import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import TeamList from './components/TeamList';
import TeamDetail from './components/TeamDetail';
import Onboard from './components/Onboard';

const Teams: FC = () => {
  return (
    <Routes>
      <Route index element={<TeamList />} />
      <Route path=":id" element={<TeamDetail />} />
      <Route path=":id/onboarding" element={<Onboard />} />
    </Routes>
  );
};

export default Teams;
