import { TokenTypes as T } from './types';
import { client } from '@providers/ApiProvider';

const tokenService = {
  get: async (): Promise<string> => {
    const { data, error } = await client.query<T.Result, T.Variables>({
      query: T.query,
    });

    if (error) {
      throw error;
    } else if (!data) {
      throw new Error('No token data returned from server.');
    } else {
      return data.userToken.value;
    }
  },
};

export default tokenService;
