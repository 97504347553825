import { gql } from '@apollo/client';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import { Team } from '@schema/team/types/team';

export class OrderProductLine {
  team!: Team;
  product!: Product;
  variant!: Variant;
  quantity!: number;

  static fragment = gql`
    fragment OrderProductLineFragment on OrderProductLine {
      team {
        ...TeamFragment
      }
      product {
        ...ProductFragment
      }
      variant {
        ...VariantFragment
      }
      quantity
    }
  `;
}
