import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export interface CollapseProps {
  open: boolean;
}

const Collapse: FC<PropsWithChildren<CollapseProps>> = ({ open, children }) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
          style={{ overflow: 'hidden' }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Collapse;

const variants = {
  open: { height: 'auto', opacity: 1 },
  closed: { height: 0, opacity: 0 },
};
