import { gql } from '@apollo/client';
import { Cart } from './cart';
import { DonationLine } from '@schema/line/types/donation.line';
import { ProductLine } from '@schema/line/types/product.line';
import { Team } from '@schema/team/types/team';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import { CartInput } from './cart.input';
import { League } from '@schema/league/types/league';
import { User } from '@schema/user/types/user';

export namespace CartTypes {
  export namespace CartRead {
    export const query = gql`
      ${Cart.fragment}
      ${DonationLine.fragment}
      ${ProductLine.fragment}
      ${Team.fragment}
      ${Product.fragment}
      ${Variant.fragment}
      ${League.fragment}
      ${User.fragment}
      query CartRead {
        cartRead {
          ...CartFragment
        }
      }
    `;

    export type Result = {
      cartRead: Cart;
    };

    export type Variables = {};
  }

  export namespace CartWrite {
    export const mutation = gql`
      ${Cart.fragment}
      ${DonationLine.fragment}
      ${ProductLine.fragment}
      ${Team.fragment}
      ${Product.fragment}
      ${Variant.fragment}
      ${League.fragment}
      ${User.fragment}
      mutation CartWrite($data: CartInput!) {
        cartWrite(data: $data) {
          ...CartFragment
        }
      }
    `;

    export type Result = {
      cartWrite: Cart;
    };

    export type Variables = {
      data: CartInput;
    };
  }

  export namespace Checkout {
    export const mutation = gql`
      mutation Checkout($data: CartInput!) {
        checkout(data: $data)
      }
    `;

    export type Result = {
      checkout: string;
    };

    export type Variables = {
      data: CartInput;
    };
  }
}
