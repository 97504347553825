import TeamOnboarding from '@components/input/TeamOnboarding';
import BasePage from '@components/layout/BasePage';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import React, { FC } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

const Onboard: FC = () => {
  const alert = useAlert();
  const nav = useNavigate();
  const { id } = useParams();

  if (!id) throw new Error('Team is required in Onboard component');

  const { data, loading } = schema.team.read({
    variables: { id: id! },
  });

  const team = data?.teamRead ?? null;

  if (team && !team.coach) {
    return <Navigate to={`/admin/teams/${id}`} />;
  }
  return (
    <BasePage
      back={{
        label: 'Team details',
        path: `/admin/teams/${id}`,
      }}
      title={team?.name ?? ''}
      loading={loading}
    >
      <TeamOnboarding
        team={id}
        onComplete={() => {
          alert('Onboarding complete', 'success');
          nav(`/admin/teams/${id}`);
        }}
      />
    </BasePage>
  );
};

export default Onboard;
