export const niceJoin = (
  arr: string[],
  separator = ', ',
  lastSeparator = ' and ',
) => {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(lastSeparator);
  }

  return arr.slice(0, -1).join(separator) + lastSeparator + arr.slice(-1);
};
