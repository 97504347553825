import { Team } from '@schema/team/types/team';
import React, { FC } from 'react';

export interface QuestionsProps {
  team: Team;
}

const Questions: FC<QuestionsProps> = ({ team }) => {
  return <></>;
};

export default Questions;
