import Icon from '@components/display/Icon';
import { Box, IconButton, Typography, useTheme } from '@mui/joy';
import React, { FC, ReactNode } from 'react';

export interface AlertProps {
  message: string;
  icon: ReactNode;
  onClose: () => void;
}

const Alert: FC<AlertProps> = ({ message, icon, onClose }) => {
  const { palette, radius } = useTheme();
  return (
    <Box
      sx={{
        borderRadius: radius.md,
        zIndex: 9999,
        background: palette.neutral[800],
        display: 'flex',
        alignItems: 'flex-start',
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.5rem',
        }}
      >
        {icon}
      </Box>
      <Box sx={{ flex: 1, p: 1 }}>
        <Typography
          sx={{
            maxWidth: '300px',
            color: palette.common.white,
          }}
        >
          {message}
        </Typography>
      </Box>
      <IconButton onClick={onClose}>
        <Icon.X
          style={{
            color: palette.common.white,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default Alert;
