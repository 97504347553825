import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardList from './components/DashboardList';
import DashboardHome from './components/DashboardHome';

const Dashboard: FC = () => {
  return (
    <Routes>
      <Route index element={<DashboardList />} />
      <Route path=":team" element={<DashboardHome />} />
    </Routes>
  );
};

export default Dashboard;
