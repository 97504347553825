import React, { FC, PropsWithChildren } from 'react';
import { auth } from '../AuthProvider/config';
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const uri = process.env.REACT_APP_API_URL;

if (!uri) {
  throw new Error('REACT_APP_API_URL is not set');
}

const asyncAuthLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser?.getIdToken();
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const httpLink = new HttpLink({ uri });

const link = from([asyncAuthLink, httpLink]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApiProvider;
