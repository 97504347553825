import Icon, { CoreIcon } from '@components/display/Icon';
import { Box, Button, Stack, Typography, useTheme } from '@mui/joy';
import { TeamDetail } from '@schema/team/types/team';
import React, { FC, ReactNode } from 'react';
import TeamForm from '../../../TeamForm';
import CoachForm from '../../../CoachForm';
import { useNavigate } from 'react-router-dom';

export interface TeamDetailsProps {
  team: TeamDetail;
}

const Detail: FC<{ label: string; value: ReactNode }> = ({ label, value }) => {
  const { palette, radius } = useTheme();
  return (
    <Box>
      <Typography level="body-xs">{label}</Typography>
      {typeof value === 'string' ? (
        <Typography level="title-sm">{value}</Typography>
      ) : (
        value
      )}
    </Box>
  );
};

const TeamDetails: FC<TeamDetailsProps> = ({ team }) => {
  const { palette } = useTheme();

  const nav = useNavigate();

  const details: Record<string, ReactNode> = {
    Name: team.name,
    League: team.league?.name ?? 'Unassigned',
    Coach: team.coach?.displayName ?? team.coach?.email ?? 'Unassigned',
    'Storefront URL': (
      <Button
        onClick={() => {
          nav(`/teams/${team.tag}`);
        }}
        variant="plain"
        color="neutral"
      >
        <Typography level="title-sm">{`${window.location.origin}/teams/${team.tag}`}</Typography>
      </Button>
    ),
    'Stripe ID': team.account,
    Onboarding: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          sx={{
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            background: team.onboarded
              ? palette.success[500]
              : palette.danger[500],
          }}
        />
        <Typography>{team.onboarded ? 'Complete' : 'Incomplete'}</Typography>
      </Box>
    ),
  };
  return (
    <Box>
      <Stack gap={2}>
        {Object.entries(details).map(([label, value]) => (
          <Detail key={label} label={label} value={value} />
        ))}
      </Stack>
      <Box p={6} />
      <Box sx={{ display: 'flex' }}>
        <Stack gap={1} direction="row">
          <TeamForm>
            {({ onClick }) => (
              <Button
                onClick={() => onClick(team)}
                variant="outlined"
                size="sm"
                endDecorator={<Icon.Edit />}
              >
                Edit Details
              </Button>
            )}
          </TeamForm>
          <CoachForm>
            {({ onClick }) => (
              <Button
                variant="outlined"
                size="sm"
                endDecorator={<CoreIcon.Coach />}
                onClick={() => onClick(team)}
              >
                Assign a {team.coach ? 'New ' : ''}Coach
              </Button>
            )}
          </CoachForm>
          {team.coach && (
            <Button
              variant="outlined"
              size="sm"
              endDecorator={<CoreIcon.Coach />}
              onClick={() => nav('onboarding')}
            >
              {team.onboarded ? 'Re-onboard' : 'Complete Onboarding'}
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default TeamDetails;
