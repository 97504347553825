import * as Joi from 'joi';
import { Role } from './role';
import { ParsedToken } from 'firebase/auth';

export class ClaimsTeam {
  id: string;
  tag: string;

  constructor(id: string, tag: string) {
    this.id = id;
    this.tag = tag;
  }

  static schema = Joi.object({
    id: Joi.string().required(),
    tag: Joi.string().required(),
  });
}

export class Claims {
  role: Role;
  teams: ClaimsTeam[];

  constructor(role: Role, teams: ClaimsTeam[]) {
    this.role = role;
    this.teams = teams;
  }

  static schema = Joi.object({
    role: Joi.string()
      .valid(...Object.values(Role))
      .required(),
    teams: Joi.array().items(ClaimsTeam.schema),
  }).unknown(true);

  static fromToken(token: ParsedToken) {
    const { error, value } = Claims.schema.validate(token, {
      abortEarly: false,
    });

    if (error) {
      return null;
    } else {
      return value as Claims;
    }
  }
}
