import React, { FC, PropsWithChildren } from 'react';
import Section, { SectionProps } from '../Section';
import { Box, Button, LinearProgress, Skeleton, Typography } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Icon from '@components/display/Icon';

export interface BasePageProps {
  title: string;
  loading?: boolean;
  subtitle?: string;
  back?: {
    label: string;
    path: string;
  };
  maxWidth?: SectionProps['maxWidth'];
  action?: React.ReactNode;
}

const BasePage: FC<PropsWithChildren<BasePageProps>> = ({
  title,
  subtitle,
  children,
  loading = false,
  back,
  maxWidth,
  action,
}) => {
  const nav = useNavigate();
  return (
    <Section
      maxWidth={maxWidth}
      sx={{
        container: {
          height: '100%',
          p: 0,
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        },
      }}
    >
      <AnimatePresence>
        {loading && (
          <motion.div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LinearProgress />
          </motion.div>
        )}
      </AnimatePresence>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box p={2} />
        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Box>
            {back && (
              <Button
                color="neutral"
                startDecorator={<Icon.ArrowLeft />}
                onClick={() => nav(back.path)}
                variant="plain"
              >
                {back.label}
              </Button>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography level="h3">
                <Skeleton loading={!title}>{title || 'Loading...'}</Skeleton>
              </Typography>
              {action}
            </Box>
            {subtitle !== undefined && (
              <Typography level="body-lg">
                <Skeleton loading={!subtitle}>
                  {subtitle || 'Loading...'}
                </Skeleton>
              </Typography>
            )}
          </Box>
        </Box>
        <Box p={1} />
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 2,
            paddingRight: 2,
            overflowX: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    </Section>
  );
};

export default BasePage;
