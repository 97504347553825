import Page from '@components/layout/Page';
import React, { FC } from 'react';
import Hero from './components/Hero';
import Features from './components/Features';
import { useNavigate } from 'react-router-dom';

const Landing: FC = () => {
  const nav = useNavigate();

  const getStarted = () => {
    nav('/teams');
  };

  const contactSales = () => {
    nav('/contact');
  };

  return (
    <Page sx={{ overflow: 'auto' }}>
      <Hero getStarted={getStarted} contactSales={contactSales} />
      <Features getStarted={getStarted} />
    </Page>
  );
};

export default Landing;
