import { Box, Button, Divider, useTheme } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface TabbedProps {
  tabKey: string;
  children: Record<string, { label: string; content: ReactNode }>;
  padding?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
}

const Tabbed: FC<TabbedProps> = ({
  tabKey,
  children,
  padding = {
    left: 0,
    right: 0,
    top: 2,
    bottom: 8,
  },
}) => {
  const { palette } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { spacing } = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const [coordinate, setCoordinate] = useState({ left: 0, width: 0 });

  const [tab, setTab] = useState(
    searchParams.get(tabKey) ?? Object.keys(children)[0],
  );

  useEffect(() => {
    if (searchParams.get(tabKey) !== tab) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set(tabKey, tab);
      setSearchParams(newParams);
    }
  }, [tab, searchParams, tabKey, setSearchParams]);

  useEffect(() => {
    if (ref.current) {
      const element = ref.current.querySelector(`#tab-${tab}`);

      if (element) {
        //  coords are relative to the ref element
        const rect = element.getBoundingClientRect();
        setCoordinate({
          left: rect.left - ref.current.getBoundingClientRect().left,
          width: rect.width,
        });
      }
    }
  }, [tab]);

  return (
    <Box
      ref={ref}
      sx={{
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <motion.div
        style={{
          width: `${coordinate.width}px`,
          height: '4px',
          borderRadius: '4px',
          background: palette.primary[500],
          position: 'absolute',
          left: coordinate.left,
          translateY: '34px',
          transition: 'left 0.2s, width 0.2s',
        }}
      ></motion.div>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {Object.keys(children).map((key) => (
          <Button
            id={`tab-${key}`}
            variant="plain"
            color={tab === key ? 'primary' : 'neutral'}
            key={key}
            onClick={() => setTab(key)}
          >
            {children[key].label}
          </Button>
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <AnimatePresence mode="popLayout">
          <motion.div
            key={tab}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Box
              sx={{
                paddingTop: spacing(padding.top ?? 0),
                paddingLeft: spacing(padding.left ?? 0),
                paddingRight: spacing(padding.right ?? 0),
                paddingBottom: spacing(padding.bottom ?? 0),
              }}
            >
              {children[tab].content}
            </Box>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default Tabbed;
