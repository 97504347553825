import Table from '@components/display/Table';
import BasePage from '@components/layout/BasePage';
import schema from '@schema/index';
import { OrderTypes } from '@schema/order/types';
import { Order } from '@schema/order/types/order';
import { User } from '@schema/user/types/user';
import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { IconButton, Stack } from '@mui/joy';
import Icon from '@components/display/Icon';
import { useNavigate } from 'react-router-dom';

const OrdersList: FC = () => {
  const nav = useNavigate();

  const [variables, setVariables] = useState<OrderTypes.OrderList.Variables>({
    skip: 0,
    take: 1000,
    filter: {},
  });

  const { data, loading } = schema.order.list({ variables });

  const orders = data?.orderList.orders ?? [];

  return (
    <BasePage title="Orders" loading={loading}>
      <Table
        stickyHeader
        size="md"
        rows={orders}
        onClick={(order) => {
          nav(order._id);
        }}
        columns={[
          {
            header: 'Customer',
            content: (row) =>
              (row.user.displayName || row.shippingAddress?.name) ??
              User.getName(row.user),
          },
          {
            header: 'Date',
            content: (row) =>
              row.date
                ? // Aug 11 2019, 12:00 AM
                  format(new Date(row.date), 'MMM dd yyyy, hh:mm a')
                : 'n/a',
            min: 'md',
          },
          {
            header: 'Team',
            content: (row) =>
              Order.getTeams(row)
                .map((team) => team.name)
                .join(', '),
            min: 'sm',
          },
          {
            header: 'Amount',
            content: (row) =>
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(Math.round(row.amountBilled / 100)),
          },
          {
            header: 'Status',
            content: (row) => row.status,
          },
          {
            header: 'Tracking',
            width: '120px',
            content: (row) => (
              <Stack direction="row" spacing={1}>
                {row.shipments.map((shipment) => {
                  if (!shipment.trackingUrl || !shipment.trackingNumber) {
                    return null;
                  } else
                    return (
                      <IconButton
                        onClick={() => {
                          window.open(shipment.trackingUrl, '_blank');
                        }}
                        variant="plain"
                        color="neutral"
                        key={`shipment_${shipment.id}`}
                      >
                        <Icon.Truck />
                      </IconButton>
                    );
                })}
              </Stack>
            ),
            min: 'md',
          },
        ]}
      />
    </BasePage>
  );
};

export default OrdersList;
