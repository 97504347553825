import Icon, { CoreIcon } from '@components/display/Icon';
import Feel from '@components/feedback/Feel';
import {
  Box,
  Typography,
  Grid,
  AspectRatio,
  Button,
  Stack,
  useTheme,
  Divider,
} from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import { CartTypes } from '@schema/cart/types';
import schema from '@schema/index';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import { Team } from '@schema/team/types/team';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ProductFormProps {
  team: Team;
  product: Product;
}

const ProductForm: FC<ProductFormProps> = ({ team, product }) => {
  const nav = useNavigate();
  const { palette, radius } = useTheme();
  const alert = useAlert();

  const [variant, setVariant] = useState<Variant>(product.variants[0]);

  const { data: cartData, loading: cartReadLoading } = schema.cart.read();

  const [handleCartWrite, { loading: cartWriteLoading }] = schema.cart.write({
    refetchQueries: [CartTypes.CartRead.query],
    onCompleted: () => {
      alert('Bag Updated', 'success');
    },
  });

  const colorClick = (color: string) => {
    const match = product.variants.find(
      (v) => v.color === color && v.size === variant.size,
    );

    if (match) {
      setVariant(match);
    } else {
      alert(`No ${color} in ${variant.size}`, 'info');
    }
  };

  const sizeClick = (size: string) => {
    const match = product.variants.find(
      (v) => v.color === variant.color && v.size === size,
    );

    if (match) {
      setVariant(match);
    } else {
      alert(`No ${size} in ${variant.color}`, 'info');
    }
  };

  const addToBag = () => {
    if (!cartData) return;
    handleCartWrite({
      variables: {
        data: {
          donations: cartData.cartRead.donations.map((donation) => ({
            team: donation.team._id,
            amount: donation.amount,
            isCustom: donation.isCustom === true,
          })),
          products: [
            ...cartData.cartRead.products.map((product) => ({
              team: product.team._id,
              product: product.product._id,
              variant: product.variant._id,
              quantity: product.quantity,
            })),
            {
              team: team._id,
              product: product._id,
              variant: variant._id,
              quantity: 1,
            },
          ],
        },
      },
    });
  };

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid xs={12} sm={12} md={6}>
          <Feel.Arrive initialScale={1.005}>
            <AspectRatio ratio={1}>
              <img src={variant.photo} alt={product.name} />
            </AspectRatio>
          </Feel.Arrive>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
          <Box>
            <Button
              color="neutral"
              startDecorator={<Icon.ArrowLeft />}
              variant="plain"
              onClick={() => nav(`/teams/${team.tag}`)}
            >
              {team.name}
            </Button>
            <Box p={1} />
            <Typography level="h4">{product.name}</Typography>
            <Box p={1.5} />
            {product.description && (
              <Box>
                <Typography>{product.description}</Typography>
              </Box>
            )}
            <AnimatePresence mode="popLayout">
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                key={`price-${variant.price}`}
              >
                <Typography level="title-md">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(Math.round(variant.price / 100))}
                </Typography>
                <Box p={1} />
              </motion.div>
            </AnimatePresence>
            <Divider />
            {product.colors.length > 1 && (
              <Box>
                <Box p={1.5} />
                <Stack gap={1} direction="row">
                  {product.colors.map((color) => {
                    const match = product.variants.find(
                      (v) => v.color === color,
                    );
                    if (!match) return null;
                    return (
                      <Feel.Pop key={`color-${color}`}>
                        <AspectRatio
                          sx={{
                            height: '72px',
                            width: '72px',
                            borderRadius: radius.sm,
                            border: `1px solid ${
                              variant.color === color
                                ? palette.neutral[500]
                                : palette.background.body
                            }`,
                          }}
                          ratio={1}
                        >
                          <img
                            src={match.photo}
                            alt={color}
                            onClick={() => {
                              colorClick(color);
                            }}
                            style={{ padding: '10px', cursor: 'pointer' }}
                          />
                        </AspectRatio>
                      </Feel.Pop>
                    );
                  })}
                </Stack>
              </Box>
            )}
            {product.sizes.length > 1 && (
              <Box>
                <Box p={1.5} />
                <Grid container spacing={2}>
                  {product.sizes.map((size) => (
                    <Grid xs={6}>
                      <Button
                        sx={{
                          border: `1px solid ${
                            variant.size === size
                              ? palette.neutral[500]
                              : palette.neutral[100]
                          }`,
                        }}
                        size="lg"
                        fullWidth
                        variant="outlined"
                        color="neutral"
                        onClick={() => {
                          sizeClick(size);
                        }}
                      >
                        {size}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <Box p={2} />
            <Box>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Button
                    fullWidth
                    variant="solid"
                    color="neutral"
                    size="lg"
                    endDecorator={<CoreIcon.Bag />}
                    loading={cartWriteLoading || cartReadLoading}
                    onClick={addToBag}
                  >
                    Add to Bag
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
