import {
  useQuery,
  OperationVariables,
  QueryHookOptions,
  LazyQueryHookOptions,
  MutationHookOptions,
  useMutation,
  useLazyQuery,
  ApolloError,
} from '@apollo/client';
import { useAlert } from '@providers/AlertProvider';
import { DocumentNode } from 'graphql';

const useErrorHandler = () => {
  const alert = useAlert();

  const onError = (error: ApolloError) => {
    try {
      const errorFormat = error.graphQLErrors
        .map((error) => (error.extensions.originalError as any).message)
        .join('\n');

      alert(errorFormat || error.message, 'error');
    } catch (e) {
      alert(error.message, 'error');
    }
  };

  return onError;
};

const query =
  <Result, Variables extends OperationVariables = {}>(query: DocumentNode) =>
  (options?: QueryHookOptions<Result, Variables>) => {
    const onError = useErrorHandler();

    return useQuery<Result, Variables>(query, {
      ...options,
      onError: options?.onError ?? onError,
    });
  };

const queryLazy =
  <Result, Variables extends OperationVariables = {}>(query: DocumentNode) =>
  (options?: LazyQueryHookOptions<Result, Variables>) => {
    const onError = useErrorHandler();

    return useLazyQuery<Result, Variables>(query, {
      ...options,
      onError: options?.onError ?? onError,
    });
  };

const mutation =
  <Result, Variables extends OperationVariables = {}>(mutation: DocumentNode) =>
  (options?: MutationHookOptions<Result, Variables>) => {
    const onError = useErrorHandler();

    return useMutation<Result, Variables>(mutation, {
      ...options,
      onError: options?.onError ?? onError,
    });
  };

const datahook = { query, queryLazy, mutation };

export default datahook;
