import { useScreenSize } from './useScreenSize';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useBreakpoint = (): {
  breakpoint: Breakpoint;
  width: number;
  down: (breakpoint: Breakpoint) => boolean;
  up: (breakpoint: Breakpoint) => boolean;
} => {
  const { width } = useScreenSize();

  const breakpoint = Object.keys(breakpoints)
    .reverse()
    .find((key) => width >= breakpoints[key as Breakpoint]) as Breakpoint;

  const up = (breakpoint: Breakpoint) => width >= breakpoints[breakpoint];

  const down = (breakpoint: Breakpoint) => width < breakpoints[breakpoint];

  return { breakpoint, width, down, up };
};
