import Icon from '@components/display/Icon';
import Logo from '@components/display/Logo';
import Section from '@components/layout/Section';
import { Box, Button, Grid, Typography, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export interface HeroProps {
  getStarted: () => void;
  contactSales: () => void;
}

const Hero: FC<HeroProps> = ({ getStarted, contactSales }) => {
  const { palette } = useTheme();

  return (
    <Section
      sx={{
        container: {
          background: palette.background.level1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box>
          <Logo height={64} />
        </Box>
      </Box>
      <Grid container>
        <Grid xs={12}>
          <Box sx={{ height: '96px' }} />
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 24 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -24 }}
              transition={{ duration: 0.5 }}
            >
              <Typography
                level="h1"
                sx={{
                  fontSize: '4rem',
                  whiteSpace: 'pre-line',
                }}
              >
                {`Effortless fundraising
        for you and your team.`}
              </Typography>
              <Box p={1} />
              <Typography
                sx={{
                  maxWidth: '600px',
                }}
                level="body-lg"
              >
                Fundraising for your team made easy. Collect donations and sell
                custom apparel from one convenient platform.
              </Typography>
              <Box p={2} />
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                }}
              >
                <Button
                  sx={{ borderRadius: '24px' }}
                  endDecorator={<Icon.ArrowRight />}
                  onClick={getStarted}
                >
                  Get Started
                </Button>
                <Button
                  color="neutral"
                  variant="plain"
                  endDecorator={<Icon.ArrowRight />}
                  onClick={contactSales}
                >
                  Contact Sales
                </Button>
              </Box>
            </motion.div>
          </AnimatePresence>
          <Box sx={{ height: '96px' }} />
        </Grid>
      </Grid>
    </Section>
  );
};

export default Hero;
