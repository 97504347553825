import { gql } from '@apollo/client';
import { Identified } from '@schema/identified/identified';
import { DonationLine } from '@schema/line/types/donation.line';
import { ProductLine } from '@schema/line/types/product.line';

export class Cart extends Identified {
  donations!: DonationLine[];
  products!: ProductLine[];

  static fragment = gql`
    fragment CartFragment on Cart {
      _id
      donations {
        ...DonationLineFragment
      }
      products {
        ...ProductLineFragment
      }
    }
  `;
}
