import { Button, ButtonProps, useTheme } from '@mui/joy';
import React, { FC } from 'react';

export interface ActionProps extends ButtonProps {}

const Action: FC<ActionProps> = ({ sx, ...rest }) => {
  const { shadow } = useTheme();
  return (
    <Button
      sx={{
        position: 'absolute',
        bottom: '32px',
        right: '0px',
        borderTopLeftRadius: '24px',
        borderBottomLeftRadius: '24px',
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
        boxShadow: shadow.md,
        ...sx,
      }}
      {...rest}
    />
  );
};

export default Action;
