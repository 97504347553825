import Nav from '@components/layout/Nav';
import Page from '@components/layout/Page';
import Sidebar from '@components/layout/Sidebar';
import React, { FC, ReactElement, ReactNode } from 'react';
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Leagues from './components/Leagues';
import Orders from './components/Orders';
import Products from './components/Products';
import System from './components/System';
import Users from './components/Users';
import { Box } from '@mui/joy';
import Icon, { CoreIcon } from '@components/display/Icon';
import Teams from './components/Teams';
import { useBreakpoint } from '@hooks/useBreakpoint';

type View = {
  icon: ReactNode;
  label: string;
  path: string;
  element: ReactElement;
};

const views: View[] = [
  {
    icon: <CoreIcon.Team />,
    label: 'Teams',
    path: 'teams/*',
    element: <Teams />,
  },
  {
    icon: <CoreIcon.League />,
    label: 'Leagues',
    path: 'leagues/*',
    element: <Leagues />,
  },
  {
    icon: <CoreIcon.User />,
    label: 'Users',
    path: 'users/*',
    element: <Users />,
  },
  {
    icon: <CoreIcon.Order />,
    label: 'Orders',
    path: 'orders/*',
    element: <Orders />,
  },
  {
    icon: <CoreIcon.Product />,
    label: 'Products',
    path: 'products/*',
    element: <Products />,
  },
  {
    icon: <Icon.Settings />,
    label: 'System',
    path: 'system/*',
    element: <System />,
  },
];

const Admin: FC = () => {
  const nav = useNavigate();
  const { pathname } = useLocation();
  const { down } = useBreakpoint();
  const small = down('md');

  return (
    <Nav>
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'stretch',
          height: '100%',
          flexDirection: small ? 'column' : 'row',
        }}
      >
        <Sidebar
          sections={[
            {
              label: 'Dashboard',
              buttons: views.map(({ label, path, icon }) => ({
                icon,
                label,
                active: pathname.startsWith(`/admin/${path.replace('/*', '')}`),
                onClick: () => nav(`/admin/${path.replace('/*', '')}`),
              })),
            },
          ]}
        />
        <Box sx={{ flex: 1, overflowY: small ? 'auto' : 'hidden' }}>
          <Routes>
            <Route
              index
              element={<Navigate to={views[0].path.replace('/*', '')} />}
            />
            {views.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Routes>
        </Box>
      </Box>
    </Nav>
  );
};

export default Admin;
