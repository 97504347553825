import { useAuth } from '@providers/AuthProvider';
import { auth } from '@providers/AuthProvider/config';
import { FirebaseError } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  signInWithCredential,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useState } from 'react';

export const useSignin = (): [
  handler: (
    mode: 'signin' | 'signup',
    method: 'google' | { email: string; password: string },
  ) => void,
  loading: 'google' | 'email' | null,
] => {
  const { user } = useAuth();

  const [loading, setLoading] = useState<'google' | 'email' | null>(null);

  const attemptSignin = async (
    mode: 'signin' | 'signup',
    method: 'google' | { email: string; password: string },
  ) => {
    try {
      if (!user) {
        throw new Error('User not found while attempting to link account');
      }

      if (method === 'google') {
        try {
          const google = new GoogleAuthProvider();

          google.setCustomParameters({ prompt: 'select_account' });

          await linkWithPopup(user, google);

          window.location.reload();
        } catch (e) {
          const error = e as FirebaseError;

          if (error.code === 'auth/popup-closed-by-user') {
            return;
          } else if (error.code === 'auth/credential-already-in-use') {
            const errorCredential =
              GoogleAuthProvider.credentialFromError(error);

            if (!errorCredential) {
              console.error(
                'Unable to get credential from error while linking account',
              );
              throw new Error(
                'Unable to get credential from error while linking account',
              );
            } else {
              await signInWithCredential(auth, errorCredential);
            }
          }
        }
      } else {
        try {
          const credential = EmailAuthProvider.credential(
            method.email,
            method.password,
          );

          await linkWithCredential(user, credential);
          window.location.reload();
        } catch (e) {
          const error = e as FirebaseError;

          if (mode === 'signin' || error.code === 'auth/email-already-in-use') {
            await signInWithEmailAndPassword(
              auth,
              method.email,
              method.password,
            );
          } else {
            await createUserWithEmailAndPassword(
              auth,
              method.email,
              method.password,
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(null);
    }
  };

  return [attemptSignin, loading];
};
