import admin from './admin/admin.service';
import cart from './cart/cart.service';
import league from './league/league.service';
import order from './order/order.service';
import product from './product/product.service';
import team from './team/team.service';
import token from './token/token.service';
import user from './user/user.service';

const schema = {
  admin,
  cart,
  league,
  order,
  product,
  team,
  token,
  user,
};

export default schema;
