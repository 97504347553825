import datahook from '@hooks/datahook';
import { ProductTypes as T } from './types';

const productService = {
  read: datahook.query<T.ProductRead.Result, T.ProductRead.Variables>(
    T.ProductRead.query,
  ),
  list: datahook.query<T.ProductList.Result, T.ProductList.Variables>(
    T.ProductList.query,
  ),
  count: datahook.query<T.ProductCount.Result, T.ProductCount.Variables>(
    T.ProductCount.query,
  ),
  update: datahook.mutation<T.ProductUpdate.Result, T.ProductUpdate.Variables>(
    T.ProductUpdate.mutation,
  ),
};

export default productService;
