import { gql } from '@apollo/client';
import { Team } from '@schema/team/types/team';

export class DonationLine {
  team!: Team;
  amount!: number | null;
  isCustom?: boolean;

  static fragment = gql`
    fragment DonationLineFragment on DonationLine {
      team {
        ...TeamFragment
      }
      amount
      isCustom
    }
  `;
}
