import { CoreIcon } from '@components/display/Icon';
import { Button } from '@mui/joy';
import { useClaims } from '@providers/ClaimsProvider';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CoachButton: FC = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();

  const { claims } = useClaims();

  const active = pathname.startsWith('/dashboard');

  if (!claims || claims.teams.length === 0) {
    return null;
  }

  return (
    <Button
      onClick={() => nav('/dashboard')}
      variant="plain"
      color={active ? 'primary' : 'neutral'}
      startDecorator={<CoreIcon.Coach />}
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      Coach Dashboard
    </Button>
  );
};

export default CoachButton;
