import { gql } from '@apollo/client';

export class ExternalAccount {
  last4!: string;

  static fragment = gql`
    fragment ExternalAccountFragment on ExternalAccount {
      last4
    }
  `;
}
