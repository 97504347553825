import Nav from '@components/layout/Nav';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import React, { FC, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Banner from './components/Banner';
import Catalog from './components/Catalog';
import Section from '@components/layout/Section';
import Questions from './components/Questions';
import NotReady from './components/NotReady';
import Hero from './components/Hero';

const TeamHome: FC = () => {
  const { tag } = useParams();
  const alert = useAlert();
  const nav = useNavigate();

  const catalogRef = useRef<HTMLDivElement>(null);

  const { data, loading } = schema.team.list({
    variables: {
      skip: 0,
      take: 1,
      filter: { tag },
    },
    onError: (e) => alert(e.message, 'error'),
  });

  const team = data?.teamList.teams[0] ?? null;

  useEffect(() => {
    if (data && !team) {
      nav('/teams');
      alert('Team not found', 'error');
    }
  }, [team, data]);

  return (
    <Nav>
      {team &&
        (team.onboarded ? (
          <>
            <Hero donate={() => {}} team={team} catalogRef={catalogRef} />
            {/* <Section maxWidth="md">
              <Banner team={team} />
            </Section> */}
            <Section maxWidth="md">
              <Catalog team={team} catalogRef={catalogRef} />
            </Section>
            <Section maxWidth="md">
              <Questions team={team} />
            </Section>
          </>
        ) : (
          <NotReady team={team} />
        ))}
    </Nav>
  );
};

export default TeamHome;
