import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export interface PopProps {
  scale?: number;
}

const Pop: FC<PropsWithChildren<PopProps>> = ({ children, scale = 1.05 }) => {
  return (
    <motion.div whileHover={{ scale }} initial={{ scale: 1 }}>
      {children}
    </motion.div>
  );
};

export default Pop;
