import Icon from '@components/display/Icon';
import Nav from '@components/layout/Nav';
import { useLoading } from '@hooks/useLoading';
import { Box, Button, LinearProgress, Typography } from '@mui/joy';
import { useAuth } from '@providers/AuthProvider';
import { useClaims } from '@providers/ClaimsProvider';
import { hasRole } from 'auth/hasRole';
import { Role } from 'auth/role';
import React, { FC, PropsWithChildren } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export interface GuardProps {
  role?: Role;
  coachOnly?: boolean;
  allowAnonymous?: boolean;
}

const Guard: FC<PropsWithChildren<GuardProps>> = ({
  role = Role.User,
  coachOnly = false,
  allowAnonymous = true,
  children,
}) => {
  const nav = useNavigate();
  const { team } = useParams();
  const { pathname } = useLocation();
  const { user, loading: userLoading } = useAuth();
  const { claims, loading: claimsLoading } = useClaims();

  const loading = useLoading(userLoading || claimsLoading);

  if (loading || !user)
    return (
      <Nav>
        <LinearProgress />
      </Nav>
    );
  else if (user.isAnonymous && !allowAnonymous)
    return (
      <Navigate
        to="/signin"
        state={{
          from: pathname,
        }}
      />
    );
  else if (!hasRole({ posessed: claims.role, required: role }))
    return (
      <Nav>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            padding: 8,
          }}
        >
          <Typography>
            You do not have the required permissions to view this page.
          </Typography>
        </Box>
      </Nav>
    );
  else if (coachOnly) {
    if (!team)
      throw new Error('Team url param is required for coachOnly guard');
    else if (claims.teams.every((t) => t.tag !== team && t.id !== team)) {
      return (
        <Nav>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              padding: 8,
            }}
          >
            <Typography level="h4">Not Authorized</Typography>
            <Typography>
              You do not have the required permissions to view this team's
              dashboard.
            </Typography>
            <Button onClick={() => nav(-1)} startDecorator={<Icon.ArrowLeft />}>
              Back
            </Button>
          </Box>
        </Nav>
      );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Guard;
