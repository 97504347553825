import { gql } from '@apollo/client';
import { League } from './league';
import { LeagueFilter } from './league.filter';
import { LeagueCreate } from './league.create';
import { LeagueUpdate } from './league.update';
import { LeagueList } from './league.list';

export namespace LeagueTypes {
  export namespace Count {
    export const query = gql`
      query LeagueCount($filter: LeagueFilter!) {
        leagueCount(filter: $filter)
      }
    `;

    export type Result = { leagueCount: number };
    export type Variables = { filter: LeagueFilter };
  }

  export namespace Read {
    export const query = gql`
      ${League.fragment}
      query LeagueRead($id: String!) {
        leagueRead(id: $id) {
          ...LeagueFragment
        }
      }
    `;

    export type Result = { leagueRead: League };
    export type Variables = { id: string };
  }

  export namespace List {
    export const query = gql`
      ${LeagueList.fragment}
      ${League.fragment}
      query LeagueList($filter: LeagueFilter!, $skip: Int!, $take: Int!) {
        leagueList(filter: $filter, skip: $skip, take: $take) {
          ...LeagueListFragment
        }
      }
    `;

    export type Result = { leagueList: LeagueList };
    export type Variables = {
      filter: LeagueFilter;
      skip: number;
      take: number;
    };
  }

  export namespace Create {
    export const mutation = gql`
      ${League.fragment}
      mutation LeagueCreate($data: LeagueCreate!) {
        leagueCreate(data: $data) {
          ...LeagueFragment
        }
      }
    `;

    export type Result = { leagueCreate: League };
    export type Variables = { data: LeagueCreate };
  }

  export namespace Update {
    export const mutation = gql`
      ${League.fragment}
      mutation LeagueUpdate($id: String!, $data: LeagueUpdate!) {
        leagueUpdate(id: $id, data: $data) {
          ...LeagueFragment
        }
      }
    `;

    export type Result = { leagueUpdate: League };
    export type Variables = { id: string; data: LeagueUpdate };
  }
}
