import Feel from '@components/feedback/Feel';
import { Button } from '@mui/joy';
import React, { FC, ReactNode } from 'react';

export interface SideButtonProps {
  icon: ReactNode;
  label: string;
  active: boolean;
  onClick: () => void;
}

const SideButton: FC<SideButtonProps> = ({ icon, label, active, onClick }) => {
  return (
    <Feel.Pop>
      <Button
        fullWidth
        sx={{
          justifyContent: 'flex-start',
          paddingLeft: 1,
        }}
        startDecorator={icon}
        variant="plain"
        color={active ? 'primary' : 'neutral'}
        onClick={onClick}
      >
        {label}
      </Button>
    </Feel.Pop>
  );
};

export default SideButton;
