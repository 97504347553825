import Form from '@components/layout/Form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Typography,
  Option,
} from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { TeamTypes } from '@schema/team/types';
import { Team } from '@schema/team/types/team';
import React, { FC, ReactNode, useState } from 'react';

export interface CoachFormProps {
  children: (provided: { onClick: (team: Team) => void }) => ReactNode;
}

const CoachForm: FC<CoachFormProps> = ({ children }) => {
  const alert = useAlert();

  const [team, setTeam] = useState<Team | null>(null);
  const [coach, setCoach] = useState<string | null>(null);

  const onClose = () => {
    setTeam(null);
    setCoach(null);
  };

  const [handleUpdate, { loading: updateLoading }] = schema.team.update({
    refetchQueries: [TeamTypes.Read.query, TeamTypes.List.query],
    onCompleted: () => {
      alert('Coach assignment has been updated.', 'success');
      onClose();
    },
  });

  const { data: userData, loading: usersLoading } = schema.user.list();

  const onClick = (team: Team) => {
    setTeam(team);
    setCoach(team.coach?.uid ?? null);
  };

  const onSubmit = () => {
    if (team && coach) {
      handleUpdate({
        variables: {
          id: team._id,
          data: { coach },
        },
      });
    }
  };

  const loading = updateLoading || usersLoading;

  const users = userData?.userList.users ?? [];

  return (
    <>
      {children({
        onClick,
      })}
      <Form
        title="Coach Assignment"
        subtitle={team?.name}
        onClose={onClose}
        open={team !== null}
        action={
          <Box>
            {team?.coach && (
              <Box>
                <Typography level="body-sm" sx={{ maxWidth: '316px' }}>
                  {`By reassigning the coach, the current coach (${team.coach.displayName}) will be removed from the team. They will no longer have access to the team's funds.`}
                </Typography>
                <Box p={1} />
              </Box>
            )}
            <Button type="submit" size="lg" fullWidth loading={loading}>
              Save
            </Button>
          </Box>
        }
        onSubmit={onSubmit}
      >
        <FormControl>
          <FormLabel>New Coach</FormLabel>
          <Select
            size="lg"
            variant="soft"
            value={coach}
            onChange={(e, v) => {
              setCoach(v);
            }}
            renderValue={(value) => {
              const match = users.find((user) => user.uid === value?.value);
              return match?.displayName ?? match?.email ?? match?.uid ?? '';
            }}
            placeholder="Select a coach"
          >
            {users.map((user) => (
              <Option key={user.uid} value={user.uid}>
                <Box>
                  <Typography level="body-lg">
                    {user.displayName ?? 'Unnamed User'}
                  </Typography>
                  <Typography>{user.email}</Typography>
                </Box>
              </Option>
            ))}
          </Select>
        </FormControl>
      </Form>
    </>
  );
};

export default CoachForm;
