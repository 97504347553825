import Table from '@components/display/Table';
import BasePage from '@components/layout/BasePage';
import schema from '@schema/index';
import { UserTypes } from '@schema/user/types';
import React, { FC, useState } from 'react';
import UserForm from './components/UserForm';
import { useTheme } from '@mui/joy';

const Users: FC = () => {
  const { radius } = useTheme();

  const [variables, setVariables] = useState<UserTypes.List.Variables>({});

  const { data, loading } = schema.user.list({ variables });

  const users = data?.userList.users ?? [];

  return (
    <UserForm>
      {({ onClick }) => (
        <BasePage title="Users" loading={loading}>
          <Table
            stickyHeader
            size="lg"
            rows={users}
            onClick={onClick}
            columns={[
              {
                header: 'Name',
                content: (row) => row.displayName,
              },
              {
                header: 'Role',
                content: (row) => row.role,
              },
              {
                header: 'Email',
                content: (row) => row.email,
              },
              {
                header: 'Phone',
                content: (row) => row.phoneNumber,
              },
            ]}
          />
        </BasePage>
      )}
    </UserForm>
  );
};

export default Users;
