import datahook from '@hooks/datahook';
import { TeamTypes as T } from './types';

const teamService = {
  read: datahook.query<T.Read.Result, T.Read.Variables>(T.Read.query),
  readDetail: datahook.query<T.ReadDetail.Result, T.ReadDetail.Variables>(
    T.ReadDetail.query,
  ),
  list: datahook.query<T.List.Result, T.List.Variables>(T.List.query),
  listDetail: datahook.query<T.ListDetail.Result, T.ListDetail.Variables>(
    T.ListDetail.query,
  ),
  count: datahook.query<T.Count.Result, T.Count.Variables>(T.Count.query),
  create: datahook.mutation<T.Create.Result, T.Create.Variables>(
    T.Create.mutation,
  ),
  session: datahook.mutation<string, { team: string }>(T.Session.mutation),
  update: datahook.mutation<T.Update.Result, T.Update.Variables>(
    T.Update.mutation,
  ),
};

export default teamService;
