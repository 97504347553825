import { gql } from '@apollo/client';
import { Team } from '@schema/team/types/team';

export class OrderDonationLine {
  team!: Team;
  amount!: number;

  static fragment = gql`
    fragment OrderDonationLineFragment on OrderDonationLine {
      team {
        ...TeamFragment
      }
      amount
    }
  `;
}
