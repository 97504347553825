import { useAuth } from '@providers/AuthProvider';
import { auth } from '@providers/AuthProvider/config';
import schema from '@schema/index';
import { Claims } from 'auth/claims';
import { Role } from 'auth/role';
import { signInWithCustomToken } from 'firebase/auth';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

const emptyClaims: Claims = { role: Role.User, teams: [] };

export interface ClaimsContext {
  claims: Claims;
  loading: boolean;
}

const Context = createContext<ClaimsContext>({
  claims: emptyClaims,
  loading: true,
});

const ClaimsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [claims, setClaims] = useState<Claims>(emptyClaims);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    setError(null);
    if (!user) {
      setClaims(emptyClaims);
      setLoading(true);
    } else {
      if (user.isAnonymous) {
        setClaims(emptyClaims);
        setLoading(false);
      } else {
        try {
          user.getIdTokenResult().then((token) => {
            const initialClaims = Claims.fromToken(token.claims);
            if (initialClaims) {
              setClaims(initialClaims);
              setLoading(false);
            } else {
              schema.token.get().then((signinToken) => {
                signInWithCustomToken(auth, signinToken).then(
                  (newCredential) => {
                    newCredential.user.getIdTokenResult(true).then((token) => {
                      const newClaims = Claims.fromToken(token.claims);
                      if (newClaims) {
                        setClaims(newClaims);
                        setLoading(false);
                      } else {
                        setError(new Error('Failed to get claims from token'));
                        setLoading(false);
                      }
                    });
                  },
                );
              });
            }
          });
        } catch (error) {
          setError(error as Error);
          setLoading(false);
        }
      }
    }
  }, [user]);

  if (error)
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
      </div>
    );

  return (
    <Context.Provider value={{ claims, loading }}>{children}</Context.Provider>
  );
};

export default ClaimsProvider;

export const useClaims = () => useContext(Context);
