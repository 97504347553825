import Collapse from '@components/layout/Collapse';
import Form from '@components/layout/Form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Typography,
  Option,
} from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { LeagueTypes } from '@schema/league/types';
import { TeamTypes } from '@schema/team/types';
import { Team } from '@schema/team/types/team';
import React, { FC, ReactNode, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

export interface TeamFormProps {
  children: (provided: { onClick: (team?: Team) => void }) => ReactNode;
}

type State = {
  name: string;
  tag: string;
  league: string | null;
  color: string;
};

const TeamForm: FC<TeamFormProps> = ({ children }) => {
  const [id, setId] = useState<string | null>(null);
  const [state, setState] = useState<State | null>(null);

  const alert = useAlert();

  const [colorOpen, setColorOpen] = useState(false);

  const [tagIsCustom, setTagIsCustom] = useState(false);

  const { leagues } = schema.league.all();

  const onClose = () => {
    setState(null);
    setId(null);
    setTagIsCustom(false);
  };

  const [handleCreate, { loading: createLoading }] = schema.team.create({
    variables: state ? { data: state } : undefined,
    refetchQueries: [
      TeamTypes.ListDetail.query,
      TeamTypes.List.query,
      TeamTypes.Count.query,
      LeagueTypes.List.query,
      LeagueTypes.Read.query,
    ],
    onCompleted: () => {
      onClose();
    },
  });

  const [handleUpdate, { loading: updateLoading }] = schema.team.update({
    variables: state && id ? { id, data: state } : undefined,
    refetchQueries: [
      TeamTypes.List.query,
      TeamTypes.ListDetail.query,
      TeamTypes.Count.query,
      TeamTypes.Read.query,
      TeamTypes.ReadDetail.query,
      LeagueTypes.List.query,
      LeagueTypes.Read.query,
    ],
    onCompleted: () => {
      onClose();
    },
  });

  const loading = createLoading || updateLoading;

  const onSubmit = () => {
    if (!state) return;
    else if (!state.name) alert("Please enter the team's name.", 'info');
    else if (id) handleUpdate();
    else handleCreate();
  };

  return (
    <>
      {children({
        onClick: (clicked) => {
          if (clicked) {
            setState({
              name: clicked.name,
              tag: clicked.tag,
              league: clicked.league?._id ?? null,
              color: clicked.color,
            });
            setId(clicked._id);
            setTagIsCustom(true);
          } else {
            setState({ name: '', tag: '', league: null, color: '#000000' });
            setId(null);
            setTagIsCustom(false);
          }
        },
      })}
      <Form
        open={Boolean(state)}
        onClose={onClose}
        title={id ? 'Update Team' : 'Create Team'}
        action={
          <Button type="submit" fullWidth size="lg" loading={loading}>
            Save
          </Button>
        }
        onSubmit={onSubmit}
      >
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Name"
            variant="soft"
            size="lg"
            value={state?.name ?? ''}
            onChange={(e) => {
              if (!state) return;
              setState({
                ...state,
                name: e.target.value,
                tag: tagIsCustom ? state.tag : getTag(e.target.value),
              });
            }}
          />
        </FormControl>
        <Box p={1.5} />
        <FormControl>
          <FormLabel>Storefront URL</FormLabel>
          <Input
            placeholder="url"
            variant="soft"
            size="lg"
            value={state?.tag ?? ''}
            onChange={(e) => {
              if (!state) return;
              setTagIsCustom(true);
              setState({ ...state, tag: getTag(e.target.value) });
            }}
            startDecorator={
              <Typography
                level="body-lg"
                sx={{ marginRight: -1, opacity: 0.5 }}
              >
                oneteamgoal.com/team/
              </Typography>
            }
          />
        </FormControl>
        <Box p={1.5} />
        <FormControl>
          <FormLabel>League</FormLabel>
          <Select
            placeholder="League"
            variant="soft"
            size="lg"
            value={state?.league ?? 'none'}
            onChange={(e, val) => {
              if (!state) return;
              setState({
                ...state,
                league: val ? (val === 'none' ? null : val) : null,
              });
            }}
          >
            <Option value="none">None</Option>
            {leagues.map((league) => (
              <Option key={league._id} value={league._id}>
                {league.name}
              </Option>
            ))}
          </Select>
        </FormControl>
        <Box p={1.5} />
        <FormControl>
          <FormLabel>Primary Color</FormLabel>
          <Button
            color="neutral"
            variant="soft"
            size="lg"
            sx={{
              justifyContent: 'flex-start',
              gap: 1,
              paddingLeft: 1,
            }}
            onClick={() => setColorOpen(!colorOpen)}
          >
            <Box
              sx={{
                height: '1.5rem',
                width: '1.5rem',
                borderRadius: '50%',
                backgroundColor: state?.color ?? '#000000',
              }}
            />
            {state?.color ?? '#000000'}
          </Button>
          <Collapse open={colorOpen}>
            <Box p={1} />
            <HexColorPicker
              color={state?.color ?? '#000000'}
              onChange={(color) => {
                if (!state) return;
                setState({ ...state, color });
              }}
            />
          </Collapse>
        </FormControl>
      </Form>
    </>
  );
};

export default TeamForm;

const getTag = (input: string): string => {
  // must be a url friendly, lowercase string, no spaces, no special characters (except -)
  const noSpaces = input.replace(/\s/g, '-');
  const noSpecialChars = noSpaces.replace(/[^a-zA-Z0-9-]/g, '');
  return noSpecialChars.toLowerCase();
};
