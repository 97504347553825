import { Role } from './role';

export const hasRole = (config: {
  posessed: Role;
  required: Role;
}): boolean => {
  const levels: Record<Role, number> = {
    [Role.Admin]: 2,
    [Role.Owner]: 1,
    [Role.User]: 0,
  };

  return levels[config.posessed] >= levels[config.required];
};
