import Icon from '@components/display/Icon';
import C from '@components/layout/C';
import Page from '@components/layout/Page';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Typography,
  useTheme,
} from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignin } from './handleSignin';
import { focus } from '@utils/focus';
import { useAuth } from '@providers/AuthProvider';
import { useClaims } from '@providers/ClaimsProvider';
import { Role } from 'auth/role';
import { FaGoogle } from 'react-icons/fa';

export interface AuthFormProps {
  mode: 'signin' | 'signup';
}

type State = {
  email: string;
  password: string;
  showPassword: boolean;
};

const AuthForm: FC<AuthFormProps> = ({ mode }) => {
  const nav = useNavigate();
  const { user } = useAuth();
  const { claims } = useClaims();
  const { state: urlState } = useLocation();

  const [state, setState] = useState<State>({
    email: '',
    password: '',
    showPassword: false,
  });

  const [handleSignin, loading] = useSignin();

  const validate = (): string | null => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!state.email) {
      focus('email');
      return 'Please enter an email';
    } else if (!emailRegex.test(state.email)) {
      focus('email');
      return 'Please enter a valid email';
    } else if (!state.password) {
      focus('password');
      return 'Please enter a password';
    } else {
      return null;
    }
  };

  const { breakpoints } = useTheme();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      if (claims?.role === Role.Admin) {
        nav('/admin');
      } else if (claims?.teams.length > 0) {
        nav('/dashboard');
      } else {
        nav('/teams');
      }
    }
  }, [claims, user]);

  return (
    <Page
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
      >
        <IconButton
          onClick={() => {
            nav('/');
          }}
        >
          <Icon.ArrowLeft />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          [breakpoints.down('sm')]: {
            flex: 1,
            width: '100%',
          },
        }}
      >
        <C>
          <Typography level="h2">
            {mode === 'signin' ? 'Signin' : 'Signup'}
          </Typography>
        </C>
        <Box sx={{ p: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Divider />
          </Box>
          <Typography>with</Typography>
          <Box sx={{ flex: 1 }}>
            <Divider />
          </Box>
        </Box>
        <Box sx={{ p: 1 }} />
        <Button
          size="lg"
          fullWidth
          color="neutral"
          variant="outlined"
          loading={loading === 'google'}
          disabled={loading === 'email'}
          onClick={() => handleSignin(mode, 'google')}
          sx={{
            position: 'relative',
          }}
        >
          <FaGoogle
            style={{
              position: 'absolute',
              left: '16px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
          Google
        </Button>
        <Box sx={{ p: 1 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Divider />
          </Box>
          <Typography>or</Typography>
          <Box sx={{ flex: 1 }}>
            <Divider />
          </Box>
        </Box>
        <Box sx={{ p: 1 }} />
        <form
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          onSubmit={(e) => {
            e.preventDefault();
            const holdup = validate();
            if (!holdup) handleSignin(mode, state);
            else {
              alert(holdup);
            }
          }}
        >
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              variant="soft"
              size="lg"
              placeholder="Email"
              name="email"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
            />
          </FormControl>
          <Box sx={{ p: 1 }} />
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              variant="soft"
              size="lg"
              placeholder="Password"
              name="password"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
            />
          </FormControl>
          <Box sx={{ p: 1.5, flex: 1 }} />
          <Button
            loading={loading === 'email'}
            disabled={loading === 'google'}
            type="submit"
            size="lg"
            fullWidth
          >
            {mode === 'signin' ? 'Signin' : 'Signup'}
          </Button>
          <Box sx={{ p: 1 }} />
          <C>
            <Typography
              endDecorator={
                <Button
                  onClick={() => {
                    nav(mode === 'signin' ? '/signup' : '/signin');
                  }}
                  variant="plain"
                >
                  {mode === 'signin' ? 'Signup' : 'Signin'}
                </Button>
              }
            >
              {mode === 'signin'
                ? 'Need an account?'
                : 'Already have an account?'}
            </Typography>
          </C>
        </form>
      </Box>
    </Page>
  );
};

export default AuthForm;
