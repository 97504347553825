import React, { FC } from 'react';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import { useConnect } from '@hooks/connect';

export interface TeamOnboardingProps {
  team: string;
  onComplete: () => void;
}

const TeamOnboarding: FC<TeamOnboardingProps> = ({ team, onComplete }) => {
  const connect = useConnect(team);

  return (
    <ConnectComponentsProvider connectInstance={connect}>
      <ConnectAccountOnboarding
        onExit={() => {
          onComplete();
        }}
      />
    </ConnectComponentsProvider>
  );
};

export default TeamOnboarding;
