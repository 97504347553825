import Guard from '@components/auth/Guard';
import BasePage from '@components/layout/BasePage';
import Nav from '@components/layout/Nav';
import Tabbed from '@components/layout/Tabbed';
import schema from '@schema/index';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import Overview from './components/Overview';
import Accounts from './components/Accounts';
import { useAuth } from '@providers/AuthProvider';
import { User } from '@schema/user/types/user';
import {
  ConnectComponentsProvider,
  ConnectBalances,
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectPayments,
  ConnectPaymentDetails,
  ConnectPayouts,
  ConnectPayoutsList,
} from '@stripe/react-connect-js';
import { TeamDetail } from '@schema/team/types/team';
import { useConnect } from '@hooks/connect';

export interface DashboardHomeProps {}

const Dashboard: FC<{ team: TeamDetail }> = ({ team }) => {
  const connect = useConnect(team._id);
  return (
    <ConnectComponentsProvider connectInstance={connect}>
      <Tabbed tabKey="cv">
        {{
          Balances: { label: 'Balances', content: <ConnectBalances /> },
          AccountManagement: {
            label: 'Account Management',
            content: <ConnectAccountManagement />,
          },
          Payments: { label: 'Payments', content: <ConnectPayments /> },
          Payouts: { label: 'Payouts', content: <ConnectPayouts /> },
        }}
      </Tabbed>
    </ConnectComponentsProvider>
  );
};

const DashboardHome: FC<DashboardHomeProps> = ({}) => {
  const { user } = useAuth();
  const { team: id } = useParams();

  const { data, loading } = schema.team.readDetail({
    variables: {
      id: id!,
    },
  });

  const team = data?.teamRead ?? null;
  return (
    <Guard allowAnonymous={false} coachOnly>
      <Nav loading={loading}>
        <BasePage
          title={team?.name ?? ''}
          subtitle={
            team && user && team.coach && user.uid === team.coach.uid
              ? User.getName(team.coach).split(' ').length === 2
                ? `Welcome, Coach ${User.getName(team.coach).split(' ')[1]} 👋`
                : `Welcome, ${User.getName(team.coach)}`
              : ''
          }
        >
          {team && <Dashboard team={team} />}
        </BasePage>
      </Nav>
    </Guard>
  );
};

export default DashboardHome;
