import Form from '@components/layout/Form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Option,
  Select,
} from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { UserTypes } from '@schema/user/types';
import { User } from '@schema/user/types/user';
import { UserCreate } from '@schema/user/types/user.create';
import { UserUpdate } from '@schema/user/types/user.update';
import { Role } from 'auth/role';
import React, { FC, ReactNode, useState } from 'react';

export interface UserFormProps {
  children: (provided: { onClick: (user?: User) => void }) => ReactNode;
}

type State =
  | ({ type: 'create' } & { data: UserCreate })
  | ({ type: 'update' } & { data: UserUpdate });

const UserForm: FC<UserFormProps> = ({ children }) => {
  const [id, setId] = useState<string | null>(null);
  const [state, setState] = useState<State | null>(null);

  const alert = useAlert();

  const onClose = () => {
    setState(null);
    setId(null);
  };

  const [handleCreate, { loading: createLoading }] = schema.user.create({
    variables:
      state && state.type === 'create'
        ? {
            data: state.data,
          }
        : undefined,
    refetchQueries: [UserTypes.List.query],
    onCompleted: () => {
      alert('User created successfully.', 'success');
      onClose();
    },
    onError: (error) => {
      alert(error.message, 'error');
    },
  });

  const [handleUpdate, { loading: updateLoading }] = schema.user.update({
    variables:
      state && state.type === 'update' && id
        ? {
            uid: id,
            data: {
              displayName: state.data.displayName,
              email: state.data.email,
              role: state.data.role,
              phoneNumber: state.data.phoneNumber || null,
              photoURL: state.data.photoURL,
            },
          }
        : undefined,
    refetchQueries: [UserTypes.List.query, UserTypes.Read.query],
    onCompleted: () => {
      onClose();
    },
  });

  const loading = createLoading || updateLoading;

  const onSubmit = () => {
    if (!state) return;
    else if (!state.data.displayName)
      alert("Please enter the user's name.", 'error');
    else if (state.data.displayName.split(' ').length < 2)
      alert('Please enter a first and last name.', 'error');
    else if (!state.data.email)
      alert("Please enter the user's email.", 'error');
    else if (state.type === 'create') handleCreate();
    else if (state.type === 'update') handleUpdate();
  };

  return (
    <>
      {children({
        onClick: (clicked) => {
          if (clicked) {
            setState({
              type: 'update',
              data: {
                displayName: clicked.displayName,
                email: clicked.email,
                role: clicked.role,
                phoneNumber: clicked.phoneNumber,
                photoURL: clicked.photoURL,
              },
            });
            setId(clicked.uid);
          } else {
            setState({
              type: 'create',
              data: {
                displayName: '',
                email: '',
                role: Role.User,
                password: '',
              },
            });
          }
        },
      })}
      <Form
        open={Boolean(state)}
        onClose={onClose}
        title={state?.type === 'create' ? 'Create User' : 'Update User'}
        action={
          <Button type="submit" fullWidth size="lg" loading={loading}>
            Save
          </Button>
        }
        onSubmit={onSubmit}
      >
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Name"
            variant="soft"
            size="lg"
            value={state?.data.displayName ?? ''}
            onChange={(e) => {
              if (!state) return;
              else if (state.type === 'create') {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    displayName: e.target.value,
                  },
                });
              } else {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    displayName: e.target.value,
                  },
                });
              }
            }}
          />
        </FormControl>
        <Box p={1.5} />
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            placeholder="Email"
            variant="soft"
            size="lg"
            value={state?.data.email ?? ''}
            onChange={(e) => {
              if (!state) return;
              else if (state.type === 'create') {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    email: e.target.value,
                  },
                });
              } else {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    email: e.target.value,
                  },
                });
              }
            }}
          />
        </FormControl>
        <Box p={1.5} />
        <FormControl>
          <FormLabel>Phone Number</FormLabel>
          <Input
            placeholder="Phone Number"
            variant="soft"
            size="lg"
            value={state?.data.phoneNumber ?? ''}
            onChange={(e) => {
              if (!state) return;
              else if (state.type === 'create') {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    phoneNumber: e.target.value,
                  },
                });
              } else {
                setState({
                  ...state,
                  data: {
                    ...state.data,
                    phoneNumber: e.target.value,
                  },
                });
              }
            }}
          />
          <Box p={1.5} />
          <FormControl>
            <FormLabel>Role</FormLabel>
            <Select
              variant="soft"
              size="lg"
              value={state?.data.role || Role.User}
              onChange={(e, v) => {
                if (v) {
                  if (!state) return;
                  else if (state.type === 'create') {
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        role: v,
                      },
                    });
                  } else {
                    setState({
                      ...state,
                      data: {
                        ...state.data,
                        role: v,
                      },
                    });
                  }
                }
              }}
            >
              <Option value={Role.User}>User</Option>
              <Option value={Role.Owner}>Owner</Option>
            </Select>
          </FormControl>
        </FormControl>
      </Form>
    </>
  );
};

export default UserForm;
