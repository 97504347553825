import React, { FC } from 'react';
import SideButton, { SideButtonProps } from '../SideButton';
import { Box, Stack, Typography } from '@mui/joy';

export interface SideSectionProps {
  label: string;
  buttons: SideButtonProps[];
}

const SideSection: FC<SideSectionProps> = ({ label, buttons }) => {
  return (
    <Box>
      {/* <Typography>{label}</Typography> */}
      <Stack>
        {buttons.map((button, index) => (
          <SideButton key={`side-button-${index}`} {...button} />
        ))}
      </Stack>
    </Box>
  );
};

export default SideSection;
