import BasePage from '@components/layout/BasePage';
import Tabbed from '@components/layout/Tabbed';
import { Box, Button } from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import React, { FC } from 'react';
import { JSONTree } from 'react-json-tree';

const System: FC = () => {
  const alert = useAlert();

  const [clearCache, { loading: clearCacheLoading }] = schema.admin.cacheClear({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      alert('Cache cleared', 'success');
    },
    onError: (error) => {
      alert(error.message, 'error');
    },
  });

  const { data: dbData } = schema.admin.dbStats({});

  const { data: cacheData } = schema.admin.cacheStats({});

  return (
    <BasePage title="System">
      <Tabbed tabKey="adminKey">
        {{
          Cache: {
            label: 'Cache',
            content: (
              <Box>
                <JSONTree hideRoot data={cacheData?.cacheStats} />
                <Button
                  onClick={() => {
                    clearCache();
                  }}
                  loading={clearCacheLoading}
                  variant="outlined"
                >
                  Clear
                </Button>
              </Box>
            ),
          },
          Database: {
            label: 'Database',
            content: <JSONTree hideRoot data={dbData?.dbStats} />,
          },
        }}
      </Tabbed>
    </BasePage>
  );
};

export default System;
