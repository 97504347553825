import React, { FC } from 'react';
import { ReactComponent as Crest } from './Crest.svg';
import { ReactComponent as Full } from './Full.svg';
import { useTheme } from '@mui/joy';

export interface LogoProps {
  mode?: 'crest' | 'full';
  height?: number;
}

const Logo: FC<LogoProps> = ({ mode = 'full', height = 100 }) => {
  const Svg = mode === 'crest' ? Crest : Full;

  const { palette } = useTheme();
  return <Svg height={height} />;
};

export default Logo;
