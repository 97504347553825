import Icon, { CoreIcon } from '@components/display/Icon';
import {
  Box,
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { Order } from '@schema/order/types/order';
import { OrderProductLine } from '@schema/order/types/order.product.line';
import { Team } from '@schema/team/types/team';
import { niceJoin } from '@utils/niceJoin';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface SummaryProps {
  order: Order;
}

const Item: FC<{ label: string; value: string; strong?: boolean }> = ({
  label,
  value,
  strong = false,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography level="body-md">
        {strong ? <strong>{label}</strong> : label}
      </Typography>
      <Typography level="body-md">
        {strong ? <strong>{value}</strong> : value}
      </Typography>
    </Box>
  );
};

const DonationLineItem: FC<{ team: Team; amount: number }> = ({
  team,
  amount,
}) => {
  const { palette, radius } = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          height: '112px',
          width: '112px',
          background: palette.background.level1,
          borderRadius: radius.md,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.3,
        }}
      >
        <CoreIcon.Donation fontSize="1.5rem" />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography level="title-md">{`Donation to ${team.name}`}</Typography>
        <Item
          label="Amount"
          value={new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(Math.round(amount / 100))}
        />
      </Box>
    </Box>
  );
};

const Line: FC<{ line: OrderProductLine }> = ({ line }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <img
        style={{
          height: '112px',
          width: '112px',
        }}
        src={line.product.photo}
        alt={line.product.name}
      />
      <Box sx={{ flex: 1 }}>
        <Typography level="title-md">{line.product.name}</Typography>
        {line.variant.size && <Item label="Size" value={line.variant.size} />}
        {line.variant.color && (
          <Item label="Color" value={line.variant.color} />
        )}
        <Item label="Quantity" value={line.quantity.toString()} />
        <Item
          label="Price"
          value={new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(Math.round(line.variant.price / 100))}
        />
      </Box>
    </Box>
  );
};

const Summary: FC<SummaryProps> = ({ order }) => {
  const nav = useNavigate();

  const getTeams = (): Team[] => {
    const res: Team[] = [];

    for (const donation of order.donations) {
      if (res.find((team) => team._id === donation.team._id)) {
        continue;
      } else {
        res.push(donation.team);
      }
    }

    for (const product of order.products) {
      if (res.find((team) => team._id === product.team._id)) {
        continue;
      } else {
        res.push(product.team);
      }
    }

    return res;
  };

  const teams = getTeams();

  const productSubtotal = order.products.reduce(
    (acc, line) => acc + line.variant.price * line.quantity,
    0,
  );

  const donationSubtotal = order.donations.reduce(
    (acc, donation) => acc + donation.amount,
    0,
  );

  const subtotal = productSubtotal + donationSubtotal;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography level="h3">Thank You</Typography>
        <Box p={1} />
        <Typography level="body-md">
          {niceJoin(teams.map((team) => team.name))}{' '}
          {teams.length > 1 ? 'have' : 'has'} received your{' '}
          {order.products.length === 0 ? 'donation' : 'order'}.
        </Typography>
      </Box>
      {order.products.length > 0 && (
        <Box sx={{ paddingBottom: 3 }}>
          {order.shipments.length === 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
              }}
              level="body-md"
            >
              Your order will be shipped to you as soon as possible. You can
              return to this page at any time to track your shipment.
            </Typography>
          ) : (
            <Stack gap={1}>
              {order.shipments.length > 1 && (
                <Typography>
                  Your order has been split into {order.shipments.length}{' '}
                  shipments.
                </Typography>
              )}
              {order.shipments.map((shipment, i) => {
                if (!shipment.trackingUrl || !shipment.trackingNumber)
                  return null;
                else
                  return (
                    <Tooltip
                      title={
                        !shipment.trackingUrl
                          ? 'Tracking information is not yet available.'
                          : ''
                      }
                    >
                      <Box>
                        <Button
                          fullWidth
                          size="lg"
                          disabled={!shipment.trackingUrl}
                          onClick={() => {
                            if (shipment.trackingUrl) {
                              window.open(shipment.trackingUrl, '_blank');
                            }
                          }}
                          key={`shipment-${i}`}
                        >
                          Track Shipment
                        </Button>
                      </Box>
                    </Tooltip>
                  );
              })}
            </Stack>
          )}
        </Box>
      )}
      <Divider />
      <Box p={2} />
      <Box>
        <Stack gap={2}>
          {order.donations.map((donation, i) => (
            <DonationLineItem
              key={`dl-${i}`}
              team={donation.team}
              amount={donation.amount}
            />
          ))}
          {order.products.map((line, i) => (
            <Line key={`pl-${i}`} line={line} />
          ))}
        </Stack>
      </Box>
      <Box p={2} />
      <Divider />
      <Box p={1} />
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Box sx={{ width: '112px' }} />
        <Box sx={{ flex: 1 }}>
          <Item
            label="Subtotal"
            value={new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(Math.round(subtotal / 100))}
          />
          <Item
            label="Shipping"
            value={new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(Math.round((order.amountBilled - subtotal) / 100))}
          />
          <Item
            strong
            label="Total"
            value={new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(Math.round(order.amountBilled / 100))}
          />
          {order.shippingAddress && (
            <Box>
              <Box p={2} />
              <Typography>
                <strong>Shipping Address</strong>
              </Typography>
              <Typography>{order.shippingAddress.line1}</Typography>
              <Typography>{order.shippingAddress.line2}</Typography>
              <Typography>
                {order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                {order.shippingAddress.postalCode}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box p={1} />
      <Divider />
      <Box p={1} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => {
            nav(teams.length > 1 ? '/teams' : `/teams/${teams[0].tag}`);
          }}
          endDecorator={<Icon.ArrowRight />}
        >
          {teams.length > 1 ? 'Home' : 'Back to Team'}
        </Button>
      </Box>
    </Box>
  );
};

export default Summary;
