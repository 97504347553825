import Icon from '@components/display/Icon';
import Feel from '@components/feedback/Feel';
import {
  Box,
  Divider,
  IconButton,
  Input,
  ToggleButtonGroup,
  Typography,
} from '@mui/joy';
import { ProductLine } from '@schema/line/types/product.line';
import React, { FC } from 'react';

export interface ProductLineInputProps {
  productLine: ProductLine;
  onChange: (qty: number | null) => void;
  remove: () => void;
}

const ProductLineInput: FC<ProductLineInputProps> = ({
  productLine,
  onChange,
  remove,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Feel.Pop>
          <Box
            sx={{
              cursor: 'pointer',
            }}
          >
            <img
              style={{
                height: '96px',
                width: '96px',
              }}
              src={productLine.variant.photo}
              alt={productLine.product.name}
            />
          </Box>
        </Feel.Pop>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ paddingLeft: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography level="title-sm">
                  {productLine.product.name}
                </Typography>
                <Typography level="body-sm">
                  {[
                    productLine.variant.color,
                    productLine.variant.size,
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(Math.round(productLine.variant.price / 100)),
                  ]
                    .map((v) => v)
                    .filter(Boolean)
                    .join(' - ')}
                </Typography>
              </Box>
              <IconButton onClick={remove}>
                <Icon.Trash />
              </IconButton>
            </Box>
            <Box p={1} />
            <Box sx={{ display: 'flex' }}>
              <ToggleButtonGroup size="sm">
                <Input
                  sx={{
                    p: 0,
                    paddingLeft: 1,
                    paddingRight: 1,
                    width: '96px',
                    background: 'transparent',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                      {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                    '& input[type=number]': {
                      '-moz-appearance': 'textfield', // For Firefox
                    },
                  }}
                  startDecorator="Qty: "
                  variant="outlined"
                  size="sm"
                  type="number"
                  value={
                    productLine.quantity === null ? '' : productLine.quantity
                  }
                  onChange={(e) => {
                    if (
                      typeof e.target.valueAsNumber === 'number' &&
                      e.target.valueAsNumber < 0
                    ) {
                      return;
                    } else {
                      onChange(e.target.valueAsNumber);
                    }
                  }}
                />
                <IconButton
                  disabled={
                    productLine.quantity === null || productLine.quantity === 0
                  }
                  onClick={() =>
                    onChange(
                      productLine.quantity === null
                        ? 0
                        : productLine.quantity - 1,
                    )
                  }
                >
                  <Icon.Minus />
                </IconButton>
                <IconButton
                  onClick={() =>
                    onChange(
                      productLine.quantity === null
                        ? 1
                        : productLine.quantity + 1,
                    )
                  }
                >
                  <Icon.Plus />
                </IconButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default ProductLineInput;
