import Icon from '@components/display/Icon';
import { Button } from '@mui/joy';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface HomeProps {}

const Home: FC<HomeProps> = ({}) => {
  const { pathname } = useLocation();
  const nav = useNavigate();
  return (
    <Button
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
      }}
      variant="plain"
      color={pathname.startsWith('/teams') ? 'primary' : 'neutral'}
      onClick={() => nav('/teams')}
      startDecorator={<Icon.Home />}
    >
      Home
    </Button>
  );
};

export default Home;
