import { useLoader } from '@components/feedback/Loader';
import Nav from '@components/layout/Nav';
import Section from '@components/layout/Section';
import { AspectRatio, Box, Grid, Typography } from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import React, { FC, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductForm from './components/ProductForm';

const TeamProduct: FC = () => {
  const { tag, id } = useParams();
  const alert = useAlert();
  const nav = useNavigate();

  const { data, loading: teamLoading } = schema.team.list({
    variables: {
      skip: 0,
      take: 1,
      filter: { tag },
    },
    onError: (e) => alert(e.message, 'error'),
  });

  const team = data?.teamList.teams[0] ?? null;

  useEffect(() => {
    if (data && !team) {
      nav('/teams');
      alert('Team not found', 'error');
    }
  }, [team, data]);

  const { data: productData } = schema.product.read({
    variables: { id: id! },
    onError: () => {
      alert('Product not found', 'error');
      nav(`/teams/${tag}`);
    },
  });

  const product = productData?.productRead ?? null;

  const loading = teamLoading || !product;

  useLoader('TeamProduct', loading);

  return (
    <Nav>
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <Section maxWidth="md">
          {product && team && <ProductForm team={team} product={product} />}
        </Section>
      </Box>
    </Nav>
  );
};

export default TeamProduct;
