import { gql } from '@apollo/client';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import { Team } from '@schema/team/types/team';

export class ProductLine {
  team!: Team;
  product!: Product;
  variant!: Variant;
  quantity!: number | null;

  static fragment = gql`
    fragment ProductLineFragment on ProductLine {
      team {
        ...TeamFragment
      }
      product {
        ...ProductFragment
      }
      variant {
        ...VariantFragment
      }
      quantity
    }
  `;
}
