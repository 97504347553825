import { gql } from '@apollo/client';

export class OrderCosts {
  fulfillment!: number;
  shipping!: number;

  static fragment = gql`
    fragment OrderCostsFragment on OrderCosts {
      fulfillment
      shipping
    }
  `;
}
