import { LinearProgress } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface LoaderContext {
  loading: string[];
  setLoading: (loading: string[]) => void;
}

const Context = createContext<LoaderContext>({
  loading: [],
  setLoading: () => {},
});

const LoaderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState<string[]>([]);
  return (
    <Context.Provider value={{ loading, setLoading }}>
      <AnimatePresence>
        {loading.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <LinearProgress />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </Context.Provider>
  );
};

export default LoaderProvider;

export const useLoader = (id: string, watch: boolean = true) => {
  const { loading } = useContext(Context);

  useEffect(() => {
    if (watch) {
      const index = loading.indexOf(id);
      if (index === -1) {
        loading.push(id);
      }
    } else {
      const index = loading.indexOf(id);
      if (index !== -1) {
        loading.splice(index, 1);
      }
    }
  }, [id, loading, watch]);
};
