import datahook from '@hooks/datahook';
import { OrderTypes as T } from './types';

const orderService = {
  readLazy: datahook.queryLazy<T.OrderRead.Result, T.OrderRead.Variables>(
    T.OrderRead.query,
  ),
  read: datahook.query<T.OrderRead.Result, T.OrderRead.Variables>(
    T.OrderRead.query,
  ),
  list: datahook.query<T.OrderList.Result, T.OrderList.Variables>(
    T.OrderList.query,
  ),
  count: datahook.query<T.OrderCount.Result, T.OrderCount.Variables>(
    T.OrderCount.query,
  ),
};

export default orderService;
