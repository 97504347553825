import { OrderProductLine } from './order.product.line';
import { OrderCosts } from './order.costs';
import { Address } from '@schema/address/types/address';
import { DonationLine } from '@schema/line/types/donation.line';
import { User } from '@schema/user/types/user';
import { Identified } from '@schema/identified/identified';
import { gql } from '@apollo/client';
import { Team } from '@schema/team/types/team';
import { OrderStatus } from './order.status';
import { OrderShipment } from './order.shipment';
import { OrderDonationLine } from './order.donation.line';

export class Order extends Identified {
  date?: number;
  status!: OrderStatus;
  user!: User;
  checkout!: string;
  amountBilled!: number;
  shippingAddress!: Address | null;
  donations!: OrderDonationLine[];
  products!: OrderProductLine[];
  costs!: OrderCosts;
  shipments!: OrderShipment[];

  static fragment = gql`
    fragment OrderFragment on Order {
      _id
      date
      status
      user {
        ...UserFragment
      }
      checkout
      amountBilled
      shippingAddress {
        ...AddressFragment
      }
      donations {
        ...OrderDonationLineFragment
      }
      products {
        ...OrderProductLineFragment
      }
      costs {
        ...OrderCostsFragment
      }
      shipments {
        ...OrderShipmentFragment
      }
    }
  `;

  static getTeams(order: Order): Team[] {
    const teams: Team[] = [];

    for (const donation of order.donations) {
      if (!teams.find((team) => team._id === donation.team._id)) {
        teams.push(donation.team);
      }
    }

    for (const product of order.products) {
      if (!teams.find((team) => team._id === product.team._id)) {
        teams.push(product.team);
      }
    }

    return teams;
  }
}
