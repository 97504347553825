import Icon, { CoreIcon } from '@components/display/Icon';
import Collapse from '@components/layout/Collapse';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { DonationLine } from '@schema/line/types/donation.line';
import React, { FC } from 'react';

export interface DonationLineInputProps {
  donation: DonationLine;
  onChange: (amount: number | null, isCustom: boolean) => void;
  remove: () => void;
}

const DonationLineInput: FC<DonationLineInputProps> = ({
  donation,
  onChange,
  remove,
}) => {
  const { palette, radius } = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '96px',
            width: '96px',
            minWidth: '96px',
            minHeight: '96px',
            background: palette.background.level1,
            borderRadius: radius.sm,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              fontSize: '2rem',
              color: palette.text.secondary,
            },
          }}
        >
          <CoreIcon.Donation />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ paddingLeft: 1 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box>
                <Typography level="title-sm">Donation</Typography>
                <Typography level="body-sm">{donation.team.name}</Typography>
              </Box>
              <Tooltip title="Remove">
                <IconButton onClick={remove}>
                  <Icon.Trash />
                </IconButton>
              </Tooltip>
            </Box>
            <Box p={1} />
            <Box>
              <ToggleButtonGroup size="sm">
                <Button
                  sx={{
                    fontWeight: donation.isCustom ? 600 : 300,
                  }}
                  onClick={() => onChange(10000, true)}
                >
                  Custom
                </Button>
                <Button
                  onClick={() => onChange(2500, false)}
                  sx={{
                    fontWeight: donation.isCustom
                      ? 300
                      : donation.amount === 2500
                      ? 600
                      : 300,
                  }}
                >
                  $25
                </Button>
                <Button
                  onClick={() => onChange(5000, false)}
                  sx={{
                    fontWeight: donation.isCustom
                      ? 300
                      : donation.amount === 5000
                      ? 600
                      : 300,
                  }}
                >
                  $50
                </Button>
                <Button
                  onClick={() => onChange(10000, false)}
                  sx={{
                    fontWeight: donation.isCustom
                      ? 300
                      : donation.amount === 10000
                      ? 600
                      : 300,
                  }}
                >
                  $100
                </Button>
              </ToggleButtonGroup>
              <Collapse open={donation.isCustom === true}>
                <Box>
                  <Box p={1} />
                  <Input
                    sx={{
                      border: 'none',
                      maxWidth: '190px',
                      '--Input-focusedThickness': 0,
                    }}
                    placeholder="Custom amount"
                    startDecorator="$"
                    value={
                      donation.amount === null ? '' : donation.amount / 100
                    }
                    onChange={(e) => {
                      const val =
                        e.target.valueAsNumber === null
                          ? null
                          : Math.round(e.target.valueAsNumber * 100);

                      if (val !== null && val > 1000000) {
                        alert('Maximum donation amount is $10,000');
                        return;
                      }

                      onChange(val, true);
                    }}
                    type="number"
                    // only 2 decimal places
                    slotProps={{
                      input: {
                        step: '1',
                      },
                    }}
                    onKeyDown={(e) => {
                      // prevent . or e
                      if (e.key === '.' || e.key === 'e') {
                        e.preventDefault();
                      } else if (e.key === '0') {
                        onChange(0, true);
                      }
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default DonationLineInput;
