import Icon from '@components/display/Icon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Modal,
  ModalDialog,
  Typography,
  useTheme,
} from '@mui/joy';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

export interface FormProps {
  ref?: React.Ref<HTMLDivElement>;
  open: boolean;
  onClose: () => void;
  backdropClick?: boolean;
  title: string;
  subtitle?: string;
  action?: ReactNode;
  onSubmit?: () => void;
}

const FormContent: FC<
  PropsWithChildren<Omit<FormProps, 'open' | 'backdropClick'>>
> = ({ title, subtitle, action, onSubmit, onClose, children }) => {
  const { palette, radius } = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  const attemptFocus = () => {
    if (ref.current) {
      const inputs = ref.current.querySelectorAll('input');
      const firstInput = inputs[0] as HTMLInputElement | undefined;
      if (firstInput) {
        firstInput.focus();
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      attemptFocus();
    }
  }, []);

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit && onSubmit();
      }}
    >
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 3,
            background: palette.background.body,
            borderRadius: radius.lg,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Box sx={{ paddingRight: '48px' }}>
            <Typography level="title-lg">{title}</Typography>
            {subtitle && <Typography>{subtitle}</Typography>}
          </Box>
          <IconButton onClick={onClose}>
            <Icon.X />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            p: 3,
          }}
        >
          {children}
        </Box>

        {action && (
          <>
            <Divider />
            <Box sx={{ p: 3 }}>{action}</Box>
          </>
        )}
      </Box>
    </form>
  );
};

const Form: FC<PropsWithChildren<FormProps>> = (props) => {
  const { down } = useBreakpoint();
  const small = down('md');

  const { open, onClose, backdropClick = true } = props;

  if (small) {
    return (
      <Drawer
        sx={{ p: 0 }}
        anchor="bottom"
        open={open}
        onClose={backdropClick ? onClose : undefined}
      >
        <FormContent {...props} />
      </Drawer>
    );
  } else {
    return (
      <Modal open={open} onClose={backdropClick ? onClose : undefined}>
        <ModalDialog sx={{ p: 0 }}>
          <FormContent {...props} />
        </ModalDialog>
      </Modal>
    );
  }
};

export default Form;
