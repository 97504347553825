import React, { FC } from 'react';
import SideSection, { SideSectionProps } from './components/SideSection';
import { Stack, useTheme, Select, Option } from '@mui/joy';
import FlexTop from '../FlexTop';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';

export interface SidebarProps {
  sections: SideSectionProps[];
}

const Sidebar: FC<SidebarProps> = ({ sections }) => {
  const { palette } = useTheme();
  const { down } = useBreakpoint();
  const small = down('md');
  const nav = useNavigate();

  if (small) {
    const options = sections.flatMap((section) => section.buttons);
    const active = options.find((option) => option.active);

    return (
      <Select
        startDecorator={active?.icon}
        sx={{ background: 'transparent' }}
        size="lg"
        variant="plain"
        value={active?.label}
      >
        {options.map((item) => (
          <Option
            onClick={() => {
              item.onClick();
            }}
            key={item.label}
            value={item.label}
          >
            {item.label}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <FlexTop
      sx={{
        container: {
          p: 2,
          flex: '0 0 140px',
          overflowX: 'hidden',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }} gap={3}>
        {sections.map((section) => (
          <SideSection key={`side-section-${section.label}`} {...section} />
        ))}
      </Stack>
    </FlexTop>
  );
};

export default Sidebar;
