import Table from '@components/display/Table';
import BasePage from '@components/layout/BasePage';
import schema from '@schema/index';
import { ProductTypes } from '@schema/product/types';
import { Product } from '@schema/product/types/product';
import React, { FC, useState } from 'react';
import ProductForm from './components/ProductForm';
import { Button, useTheme } from '@mui/joy';
import Icon from '@components/display/Icon';
import { useAlert } from '@providers/AlertProvider';

const Products: FC = () => {
  const { radius } = useTheme();
  const alert = useAlert();

  const [variables, setVariables] =
    useState<ProductTypes.ProductList.Variables>({
      skip: 0,
      take: 1000,
      filter: {},
    });

  const { data, loading } = schema.product.list({ variables });

  const [handleSync, { loading: syncLoading }] = schema.admin.sync({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      alert('Synced successfully', 'success');
    },
    onError: () => {
      alert('Failed to sync', 'error');
    },
  });

  const products = data?.productList.products ?? [];

  return (
    <ProductForm>
      {({ onClick }) => (
        <BasePage
          title="Products"
          loading={loading}
          action={
            <Button
              variant="outlined"
              loading={syncLoading}
              onClick={() => handleSync()}
              endDecorator={<Icon.RefreshCcw />}
            >
              Sync Printful Catalog
            </Button>
          }
        >
          <Table
            stickyHeader
            size="lg"
            rows={products}
            onClick={onClick}
            columns={[
              {
                header: '',
                content: (row) => (
                  <img
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: radius.md,
                    }}
                    src={row.photo}
                    alt={row.name}
                  />
                ),
                width: 120,
              },
              {
                header: 'Name',
                content: (row) => row.name,
              },
              {
                header: 'Scope',
                content: (row) =>
                  row.team === null && row.league === null
                    ? 'Unassigned'
                    : row.team
                    ? 'Team'
                    : 'League',
                min: 'lg',
              },
              {
                header: 'Team / League',
                content: (row) => row.team?.name ?? row.league?.name,
                min: 'md',
              },
              {
                header: 'Retail Price',
                content: (row) =>
                  row.priceMin === row.priceMax
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.priceMin / 100)
                    : `${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.priceMin / 100)} - ${new Intl.NumberFormat(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD',
                        },
                      ).format(row.priceMax / 100)}`,
                min: 'md',
              },
              {
                header: 'Wholesale Cost',
                content: (row) =>
                  row.costMin === row.costMax
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.costMin / 100)
                    : `${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.costMin / 100)} - ${new Intl.NumberFormat(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD',
                        },
                      ).format(row.costMax / 100)}`,
                min: 'md',
              },
              {
                header: 'Margin',
                content: (row) => {
                  const { margin, marginPercent } = Product.getMargins(row);
                  return marginPercent.toFixed(0) + '%';
                },
                min: 'lg',
              },
            ]}
          />
        </BasePage>
      )}
    </ProductForm>
  );
};

export default Products;
