import { Box, BoxProps } from '@mui/joy';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

export interface FlexTopProps {
  top?: ReactNode;
  bottom?: ReactNode;
  sx?: {
    container?: BoxProps['sx'];
    content?: BoxProps['sx'];
  };
}

const FlexTop: FC<PropsWithChildren<FlexTopProps>> = ({
  top,
  bottom,
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        ...sx?.container,
        overflow: 'hidden',
      }}
    >
      {top}
      <Box
        sx={{
          overflowY: 'auto',
          flex: 1,
          ...sx?.content,
        }}
      >
        {children}
      </Box>
      {bottom}
    </Box>
  );
};

export default FlexTop;
