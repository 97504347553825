import Icon from '@components/display/Icon';
import Logo from '@components/display/Logo';
import Section from '@components/layout/Section';
import { Box, Button, Grid, Typography, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Team } from '@schema/team/types/team';
import CartDrawer from '@components/display/CartDrawer';
import DonationButton from '../DonationButton';

export interface HeroProps {
  catalogRef: React.RefObject<HTMLDivElement>;
  donate: () => void;
  team: Team;
}

const Hero: FC<HeroProps> = ({ donate, team, catalogRef }) => {
  const { palette } = useTheme();

  const scrollToCatalog = () => {
    catalogRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getCoachName = () => {
    if (!team.coach) throw new Error('No coach found');
    if (!team.coach.displayName) throw new Error('No coach name found');
    const parts = team.coach.displayName.split(' ');

    if (parts.length < 2) {
      throw new Error('Coach name is not in the correct format');
    } else return `Coach ${parts[1]}`;
  };

  return (
    <Section
      sx={{
        container: {
          background: palette.background.level1,
        },
      }}
    >
      <Grid container>
        <Grid xs={12}>
          <Box sx={{ height: '72px' }} />
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 24 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -24 }}
              transition={{ duration: 0.5 }}
            >
              <Typography
                level="h1"
                sx={{
                  fontSize: '4rem',
                  whiteSpace: 'pre-line',
                }}
              >
                {`Join the effort.
        Donate to ${team.name}.`}
              </Typography>
              <Box p={1} />
              <Typography
                sx={{
                  maxWidth: '600px',
                  whiteSpace: 'pre-line',
                }}
                level="body-lg"
              >
                {`${getCoachName()} is leading the charge to raise funds for ${
                  team.name
                }. Your donation will help them reach their goal.`}
              </Typography>
              <Box p={2} />
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                }}
              >
                <CartDrawer>
                  {({ onClick, loading, total }) => (
                    <DonationButton team={team} openCart={onClick} />
                  )}
                </CartDrawer>
                <Button
                  color="neutral"
                  variant="plain"
                  endDecorator={<Icon.ArrowDown />}
                  onClick={scrollToCatalog}
                >
                  Check out the catalog
                </Button>
              </Box>
            </motion.div>
          </AnimatePresence>
          <Box sx={{ height: '72px' }} />
        </Grid>
      </Grid>
    </Section>
  );
};

export default Hero;
