import React, { ReactElement, ReactNode } from 'react';
import {
  Table as JoyTable,
  TableProps as JoyTableProps,
  useTheme,
} from '@mui/joy';
import { Breakpoint, useBreakpoint } from '@hooks/useBreakpoint';

export interface TableProps<D>
  extends Omit<JoyTableProps, 'children' | 'onClick' | 'hoverRow'> {
  rows: D[];
  columns: {
    header: ReactNode;
    content: (row: D) => ReactNode;
    min?: Breakpoint;
    width?: string | number;
  }[];
  onClick?: (row: D) => void;
}

const Table = <D,>({
  rows,
  columns,
  onClick,
  ...rest
}: TableProps<D>): ReactElement => {
  const { down } = useBreakpoint();
  const [hoverRow, setHoverRow] = React.useState<number | null>(null);
  const [clickRow, setClickRow] = React.useState<number | null>(null);
  const { palette } = useTheme();

  return (
    <JoyTable {...rest}>
      <thead>
        <tr>
          {columns.map(({ header, min, width }, index) => (
            <th key={index} hidden={min && down(min)} style={{ width }}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            onMouseEnter={() => setHoverRow(rowIndex)}
            onMouseLeave={() => setHoverRow(null)}
            onMouseDown={() => setClickRow(rowIndex)}
            onMouseUp={() => setClickRow(null)}
            onClick={() => onClick && onClick(row)}
          >
            {columns.map(({ content, min }, columnIndex) => (
              <td
                key={columnIndex}
                hidden={min && down(min)}
                style={{
                  cursor: onClick ? 'pointer' : 'default',
                  backgroundColor:
                    clickRow === rowIndex
                      ? palette.background.level2
                      : rowIndex === hoverRow
                      ? palette.background.level1
                      : 'inherit',

                  overflow: 'ellipsis',
                }}
              >
                {content(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </JoyTable>
  );
};

export default Table;
