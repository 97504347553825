import { gql } from '@apollo/client';

export class Balance {
  available!: number;
  pending!: number;

  static fragment = gql`
    fragment BalanceFragment on Balance {
      available
      pending
    }
  `;
}
