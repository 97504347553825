import Icon from '@components/display/Icon';
import Form from '@components/layout/Form';
import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Option,
  ToggleButtonGroup,
  Box,
  AspectRatio,
  useTheme,
  Stack,
} from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { ProductTypes } from '@schema/product/types';
import { Product } from '@schema/product/types/product';
import { ProductUpdate } from '@schema/product/types/product.update';
import React, { FC, ReactNode, useState } from 'react';

export interface ProductFormProps {
  children: (provided: { onClick: (product: Product) => void }) => ReactNode;
}

type State = {
  product: Product;
  updates: ProductUpdate;
};

const ProductForm: FC<ProductFormProps> = ({ children }) => {
  const { radius } = useTheme();

  const alert = useAlert();

  const [state, setState] = useState<State | null>(null);

  const onClose = () => {
    setState(null);
  };

  const [handleUpdate, { loading: updateLoading }] = schema.product.update({
    onError: (error) => alert(error.message, 'error'),
    refetchQueries: [
      ProductTypes.ProductRead.query,
      ProductTypes.ProductList.query,
    ],
    onCompleted: () => {
      alert('Product updated!', 'success');
      onClose();
    },
  });

  const { data: teamData, loading: teamLoading } = schema.team.list({
    variables: {
      filter: {},
      skip: 0,
      take: 1000,
    },
  });

  const { data: leagueData, loading: leagueLoading } = schema.league.list({
    variables: {
      filter: {},
      skip: 0,
      take: 1000,
    },
  });

  const loading = updateLoading || teamLoading || leagueLoading;

  const onSubmit = () => {
    if (state) {
      handleUpdate({
        variables: {
          id: state.product._id,
          data: state.updates,
        },
      });
    }
  };

  const teams = teamData?.teamList.teams ?? [];
  const leagues = leagueData?.leagueList.leagues ?? [];
  const options = state?.updates.team === null ? leagues : teams;

  return (
    <>
      {children({
        onClick: (product) => {
          setState({
            product,
            updates: {
              league: product.league?._id ?? null,
              team: product.team?._id ?? null,
            },
          });
        },
      })}
      <Form
        open={Boolean(state)}
        onClose={onClose}
        title={`${state?.product.name}`}
        action={
          <Stack gap={2}>
            <Button type="submit" fullWidth size="lg" loading={loading}>
              Save
            </Button>
            <Button
              endDecorator={<Icon.ArrowRight />}
              variant="plain"
              fullWidth
              size="lg"
              onClick={() => {
                const url = `https://www.printful.com/dashboard/sync/update?id=${state?.product._id}`;

                window.open(url, '_blank');
              }}
            >
              Edit with Printful
            </Button>
          </Stack>
        }
        onSubmit={onSubmit}
      >
        <Box>
          <Box>
            <AspectRatio
              ratio={1}
              sx={{
                borderRadius: radius.md,
              }}
            >
              <img src={state?.product.photo} alt={state?.product.name} />
            </AspectRatio>
          </Box>
          <Box p={1.5} />
          <FormControl>
            <FormLabel>Product Scope</FormLabel>
            <ToggleButtonGroup
              variant="soft"
              size="lg"
              value={
                !state
                  ? 'team'
                  : state?.updates.team === null
                  ? 'League'
                  : 'Team'
              }
            >
              <Button
                fullWidth
                value="Team"
                onClick={() => {
                  if (!state) return;
                  else {
                    setState({
                      ...state,
                      updates: {
                        ...state.updates,
                        team: undefined,
                        league: null,
                      },
                    });
                  }
                }}
              >
                Team
              </Button>
              <Button
                fullWidth
                value="League"
                onClick={() => {
                  if (!state) return;
                  else {
                    setState({
                      ...state,
                      updates: {
                        ...state.updates,
                        league: undefined,
                        team: null,
                      },
                    });
                  }
                }}
              >
                League
              </Button>
            </ToggleButtonGroup>
          </FormControl>
          <Box p={1.5} />
          <FormControl>
            <FormLabel>
              {state?.updates.team === null ? 'League' : 'Team'}
            </FormLabel>
            <Select
              placeholder={state?.updates.team === null ? 'League' : 'Team'}
              variant="soft"
              size="lg"
              value={
                state?.updates.team !== null
                  ? state?.updates.team ?? ''
                  : state?.updates.league ?? ''
              }
              onChange={(e, v) => {
                if (v && state) {
                  setState({
                    ...state,
                    updates: {
                      ...state.updates,
                      team: state.updates.team === null ? null : v,
                      league: state.updates.team === null ? v : null,
                    },
                  });
                }
              }}
            >
              {options.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Form>
    </>
  );
};

export default ProductForm;
