import datahook from '@hooks/datahook';
import { UserTypes as T } from './types';

const userService = {
  read: datahook.query<T.Read.Result, T.Read.Variables>(T.Read.query),
  list: datahook.query<T.List.Result, T.List.Variables>(T.List.query),
  create: datahook.mutation<T.Create.Result, T.Create.Variables>(
    T.Create.mutation,
  ),
  update: datahook.mutation<T.Update.Result, T.Update.Variables>(
    T.Update.mutation,
  ),
};

export default userService;
