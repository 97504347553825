import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Box, BoxProps, useColorScheme, useTheme } from '@mui/joy';

export interface PageProps {
  sx?: BoxProps['sx'];
}

const Page: FC<PropsWithChildren<PageProps>> = ({ sx = {}, children }) => {
  const { palette, typography } = useTheme();
  const { mode, setMode } = useColorScheme();

  // Only allow light mode
  useEffect(() => {
    if (mode !== 'light') {
      setMode('light');
    }
  }, [mode, setMode]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        background: palette.background.body,
        ...typography['body-md'],
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Page;
