import Icon from '@components/display/Icon';
import Nav from '@components/layout/Nav';
import { Box, Button, Typography, useTheme } from '@mui/joy';
import { useAuth } from '@providers/AuthProvider';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '@components/layout/BasePage';

export interface AccountProps {}

const Account: FC<AccountProps> = ({}) => {
  const { signout, user } = useAuth();
  const nav = useNavigate();
  const { palette } = useTheme();
  return (
    <Nav>
      <BasePage maxWidth="xs" title="Account">
        <Typography level="body-sm">Display Name</Typography>
        <Typography level="title-md">
          {user?.displayName || 'No display name set'}
        </Typography>
        <Box p={1} />
        <Typography level="body-sm">Email</Typography>
        <Typography level="title-md">{user?.email ?? 'No email'}</Typography>
        <Box p={1} />
        <Typography level="body-sm">Phone</Typography>
        <Typography level="title-md">
          {user?.phoneNumber ?? 'No phone number'}
        </Typography>
        <Box p={1.5} />
        <Button
          endDecorator={<Icon.ArrowRight />}
          onClick={() => {
            signout();
            nav('/');
          }}
          variant="outlined"
        >
          Signout
        </Button>
      </BasePage>
    </Nav>
  );
};

export default Account;
