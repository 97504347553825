import { CoreIcon } from '@components/display/Icon';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { Button } from '@mui/joy';
import { CartTypes } from '@schema/cart/types';
import { Cart } from '@schema/cart/types/cart';
import schema from '@schema/index';
import { Team } from '@schema/team/types/team';
import React, { FC } from 'react';

export interface DonationButtonProps {
  team: Team;
  openCart: () => void;
}

const DonationButton: FC<DonationButtonProps> = ({ team, openCart }) => {
  const { down } = useBreakpoint();
  const { data: cartData, loading: cartLoading } = schema.cart.read();

  const cart: Cart = cartData?.cartRead ?? {
    _id: 'placeholder',
    donations: [],
    products: [],
  };

  const [handleCartWrite, { loading: cartWriteLoading }] = schema.cart.write();

  const addDonation = () => {
    try {
      const hasDonation = cart.donations.some((d) => d.team._id === team._id);

      if (hasDonation) {
        openCart();
      } else {
        const amount = 2500;
        handleCartWrite({
          variables: {
            data: {
              donations: [
                ...cart.donations.map((d) => ({
                  team: d.team._id,
                  amount: d.amount,
                  isCustom: d.isCustom === true,
                })),
                { team: team._id, amount, isCustom: false },
              ],
              products: cart.products.map((p) => ({
                team: p.team._id,
                product: p.product._id,
                variant: p.variant._id,
                quantity: p.quantity,
              })),
            },
          },
          refetchQueries: [CartTypes.CartRead.query],
          onCompleted: openCart,
        });
      }
    } catch (error) {
      console.error('Error adding donation to cart');
      console.error(error);
    }
  };

  return (
    <Button
      sx={{ borderRadius: '32px' }}
      fullWidth={down('sm')}
      color="primary"
      size="lg"
      endDecorator={<CoreIcon.Donation />}
      loading={cartWriteLoading || cartLoading}
      onClick={addDonation}
    >
      Make a one-time donation
    </Button>
  );
};

export default DonationButton;
