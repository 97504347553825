import { Grid } from '@mui/joy';
import schema from '@schema/index';
import { Product } from '@schema/product/types/product';
import { ProductFilter } from '@schema/product/types/product.filter';
import React, { FC } from 'react';
import ProductCard from './components/ProductCard';
import Feel from '@components/feedback/Feel';
import { useLoader } from '@components/feedback/Loader';

export interface ProductGridProps {
  filter: ProductFilter;
  onClick: (product: Product) => void;
}

const ProductGrid: FC<ProductGridProps> = ({ filter, onClick }) => {
  const { data, loading } = schema.product.list({
    variables: { skip: 0, take: 1000, filter },
  });

  useLoader('ProductGrid', loading);

  const products = data?.productList.products ?? [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];

  return (
    <Grid container spacing={6} columnSpacing={8}>
      {products.map((product, i) => (
        <Grid xs={12} sm={6} md={4} lg={3}>
          <Feel.Arrive>
            <Feel.Pop scale={1.02}>
              <ProductCard
                key={`card-${product?._id ?? i}`}
                product={product}
                onClick={() => {
                  if (product) onClick(product);
                }}
              />
            </Feel.Pop>
          </Feel.Arrive>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductGrid;
