import { useEffect, useRef, useState } from 'react';

export const useLoading = (watch: boolean, threshold = 50) => {
  const [loading, setLoading] = useState(watch);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (watch) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setLoading(true);
    } else {
      timeoutRef.current = setTimeout(() => {
        setLoading(false);
      }, threshold);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [watch, threshold]);

  return loading;
};
