import { List } from '@schema/list/list';
import { Product } from './product';
import { gql } from '@apollo/client';

export class ProductList extends List {
  products!: Product[];

  static fragment = gql`
    fragment ProductListFragment on ProductList {
      total
      skip
      take
      products {
        ...ProductFragment
      }
    }
  `;
}
