import datahook from '@hooks/datahook';
import { AdminTypes as T } from './types';

const adminService = {
  cacheClear: datahook.mutation<T.CacheClear.Response, T.CacheClear.Variables>(
    T.CacheClear.mutation,
  ),
  dbStats: datahook.query<T.DbStats.Response, T.DbStats.Variables>(
    T.DbStats.query,
  ),
  cacheStats: datahook.query<T.CacheStats.Response, T.CacheStats.Variables>(
    T.CacheStats.query,
  ),
  sync: datahook.mutation<T.Sync.Response, T.Sync.Variables>(T.Sync.mutation),
};

export default adminService;
