import React, { FC, PropsWithChildren } from 'react';
import Top from './components/Top';
import { Box, LinearProgress } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';

export interface NavProps {
  color?: string;
  loading?: boolean;
}

const Nav: FC<PropsWithChildren<NavProps>> = ({
  children,
  loading = false,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            <LinearProgress />
          </motion.div>
        )}
      </AnimatePresence>
      <Top />
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Nav;
