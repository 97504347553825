import { gql } from '@apollo/client';
import { Role } from 'auth/role';

export class User {
  uid!: string;
  displayName?: string;
  email?: string;
  emailVerified!: boolean;
  role!: Role;
  disabled!: boolean;
  phoneNumber?: string;
  photoURL?: string;

  static fragment = gql`
    fragment UserFragment on User {
      uid
      displayName
      email
      emailVerified
      role
      disabled
      phoneNumber
      photoURL
    }
  `;

  static getName(user: User): string {
    return (
      user.displayName || user.email || user.phoneNumber || 'Anonymous User'
    );
  }
}
