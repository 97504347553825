import { gql } from '@apollo/client';
import { Token } from './token';

export namespace TokenTypes {
  export const query = gql`
    query UserToken {
      userToken {
        value
      }
    }
  `;

  export type Result = { userToken: Token };
  export type Variables = {};
}
