import { extendTheme, CssVarsProvider, CssBaseline, Theme } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';
import tinyColor from 'tinycolor2';

export const typography = {
  h1: {
    fontSize: '4rem',
    fontWeight: 800,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: '3rem',
    fontWeight: 800,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: '2.5rem',
    fontWeight: 800,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 800,
    lineHeight: 1.2,
  },
  'title-lg': {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  'title-md': {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  'title-sm': {
    fontSize: '1rem',
    fontWeight: 600,
  },
  'body-lg': {
    fontSize: '1.25rem',
    lineHeight: 1.4,
    fontWeight: 400,
  },
  'body-md': {
    fontSize: '1rem',
    lineHeight: 1.4,
    fontWeight: 400,
  },
  'body-sm': {
    fontSize: '0.875rem',
    lineHeight: 1.4,
    fontWeight: 400,
  },
  'body-xs': {
    fontSize: '0.75rem',
    lineHeight: 1.4,
    fontWeight: 400,
  },
};

const getTheme = (primaryColor?: string) => {
  const cssVarPrefix = `otg-${primaryColor ?? 'base'}`;

  const primary: Partial<Theme['colorSchemes']['light']['palette']['primary']> =
    primaryColor
      ? {
          50: tinyColor(primaryColor).lighten(40).toString(),
          100: tinyColor(primaryColor).lighten(30).toString(),
          200: tinyColor(primaryColor).lighten(20).toString(),
          300: tinyColor(primaryColor).lighten(10).toString(),
          400: tinyColor(primaryColor).lighten(5).toString(),
          500: primaryColor,
          600: tinyColor(primaryColor).darken(5).toString(),
          700: tinyColor(primaryColor).darken(10).toString(),
          800: tinyColor(primaryColor).darken(20).toString(),
          900: tinyColor(primaryColor).darken(30).toString(),
          plainHoverBg: primaryColor,
          plainActiveBg: tinyColor(primaryColor).darken(5).toString(),
        }
      : {
          50: '#fef1ec',
          100: '#fbd7c5',
          200: '#f8b08c',
          300: '#f49666',
          400: '#f37b3f',
          500: '#f16722',
          600: '#e6560f',
          700: '#ad410b',
          800: '#5e2508',
          900: '#3a1603',
          plainHoverBg: 'transparent',
          plainActiveBg: 'transparent',
        };

  return extendTheme({
    cssVarPrefix,
    spacing: 8,
    radius: {
      xs: '6px',
      sm: '10px',
      md: '14px',
      lg: '18px',
    },
    typography,
    colorSchemes: {
      light: {
        palette: {
          primary,
          neutral: {
            50: '#f2f5f8',
            100: '#e4ebf1',
            200: '#bccedc',
            300: '#87a8c0',
            400: '#466a86',
            500: '#2f4759',
            600: '#233643',
            700: '#1c2a35',
            800: '#152028',
            900: '#0e151b',
            plainHoverBg: 'transparent',
            plainActiveBg: 'transparent',
            plainActiveColor: `var(--${cssVarPrefix}-palette-neutral-500)`,
            solidBg: `var(--${cssVarPrefix}-palette-neutral-700)`,
            solidActiveBg: `var(--${cssVarPrefix}-palette-neutral-600)`,
          },
        },
      },
    },
    components: {
      JoyButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            fontWeight: 700,
            ...(ownerState.variant === 'plain' && {
              padding: 0,
            }),
          }),
        },
      },
      JoyAspectRatio: {
        styleOverrides: {
          content: {
            background: `var(--${cssVarPrefix}-palette-background-body)`,
          },
        },
      },
      JoySkeleton: {
        styleOverrides: {
          root: {
            opacity: 0.25,
          },
        },
      },
    },
  });
};

const ThemeProvider: FC<PropsWithChildren<{ primary?: string }>> = ({
  children,
  primary,
}) => {
  const theme = getTheme(primary);

  return (
    <CssVarsProvider
      theme={theme}
      defaultMode="light"
      disableNestedContext={!Boolean(primary)}
      attribute={`data-${theme.cssVarPrefix.replace('#', '')}`}
    >
      <CssBaseline />
      {children}
    </CssVarsProvider>
  );
};

export default ThemeProvider;
