import datahook from '@hooks/datahook';
import { CartTypes as T } from './types';

const cartService = {
  read: datahook.query<T.CartRead.Result, T.CartRead.Variables>(
    T.CartRead.query,
  ),
  write: datahook.mutation<T.CartWrite.Result, T.CartWrite.Variables>(
    T.CartWrite.mutation,
  ),
  checkout: datahook.mutation<T.Checkout.Result, T.Checkout.Variables>(
    T.Checkout.mutation,
  ),
};

export default cartService;
