import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export interface DropProps {}

const Drop: FC<PropsWithChildren<DropProps>> = ({ children }) => {
  return (
    <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
      {children}
    </motion.div>
  );
};

export default Drop;
