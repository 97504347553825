import Icon from '@components/display/Icon';
import Nav from '@components/layout/Nav';
import { Box, Button, Typography } from '@mui/joy';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: FC = () => {
  const nav = useNavigate();
  return (
    <Nav>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          p: 4,
          paddingTop: 10,
        }}
      >
        <Typography level="h4">Yikes... 👀</Typography>
        <Typography level="body-lg">
          We couldn't find the page you were looking for.
        </Typography>
        <Button
          onClick={() => {
            nav('/');
          }}
          startDecorator={<Icon.ArrowLeft />}
          size="lg"
        >
          Head back
        </Button>
      </Box>
    </Nav>
  );
};

export default NotFound;
