import Icon from '@components/display/Icon';
import Feel from '@components/feedback/Feel';
import BasePage from '@components/layout/BasePage';
import Nav from '@components/layout/Nav';
import { Card, Stack, Typography, useTheme } from '@mui/joy';
import { useAuth } from '@providers/AuthProvider';
import schema from '@schema/index';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardList: FC = () => {
  const nav = useNavigate();
  const { user } = useAuth();
  const { palette } = useTheme();
  const { data, loading } = schema.team.list({
    variables: {
      skip: 0,
      take: 100,
      filter: {
        coach: user!.uid,
      },
    },
    skip: !user || user.isAnonymous,
  });

  const teams = data?.teamList.teams ?? [];

  const [hovered, setHovered] = React.useState<string | null>(null);

  return (
    <Nav>
      <BasePage
        loading={loading}
        maxWidth="xs"
        title="Coach Dashboard"
        subtitle="Select your team"
      >
        <Stack gap={2}>
          <AnimatePresence>
            {teams.map((team) => (
              <motion.div
                key={`team-${team._id}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Card
                  onClick={() => nav(team._id)}
                  key={`teamCard-${team._id}`}
                  onMouseEnter={() => setHovered(team._id)}
                  onMouseLeave={() => setHovered(null)}
                  variant="soft"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: palette.neutral[200],
                    },
                    ':active': {
                      backgroundColor: palette.neutral[300],
                    },
                  }}
                >
                  <Typography>{team.name}</Typography>
                  <Icon.ArrowRight />
                </Card>
              </motion.div>
            ))}
          </AnimatePresence>
        </Stack>
      </BasePage>
    </Nav>
  );
};

export default DashboardList;
