import { gql } from '@apollo/client';

export class Address {
  name!: string;
  line1!: string;
  line2?: string;
  city!: string;
  state!: string;
  postalCode!: string;
  country!: string;

  static fragment = gql`
    fragment AddressFragment on Address {
      name
      line1
      line2
      city
      state
      postalCode
      country
    }
  `;
}
