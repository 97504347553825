import ProductGrid from '@components/display/ProductGrid';
import { Box, Typography } from '@mui/joy';
import { Team } from '@schema/team/types/team';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface CatalogProps {
  team: Team;
  catalogRef: React.RefObject<HTMLDivElement>;
}

const Catalog: FC<CatalogProps> = ({ team, catalogRef }) => {
  const nav = useNavigate();
  return (
    <Box ref={catalogRef}>
      <Typography level="h3">Get the gear 🔥</Typography>
      <Box p={2} />
      <ProductGrid
        filter={{ team: team._id, includeParentLeague: true }}
        onClick={(product) => {
          nav(product._id);
        }}
      />
    </Box>
  );
};

export default Catalog;
