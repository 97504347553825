import { Box } from '@mui/joy';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import AlertComponent from './components/Alert';

export type Alert = {
  timestamp: number;
  message: string;
  variant: 'success' | 'error' | 'warning' | 'info' | 'fire' | 'hi';
};

export interface AlertContext {
  queue: Alert[];
  enqueue: (alert: Omit<Alert, 'timestamp'>) => void;
}

const Context = createContext<AlertContext>({
  queue: [],
  enqueue: () => {},
});

const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const [queue, setQueue] = useState<Alert[]>([]);

  const enqueue = (alert: Omit<Alert, 'timestamp'>) => {
    setQueue((prev) => [...prev, { ...alert, timestamp: Date.now() }]);
  };

  useEffect(() => {
    if (queue.length > 0) {
      const interval = setInterval(() => {
        const now = Date.now();
        const maxAge = 5000;
        const expired = queue.find((alert) => now - alert.timestamp > maxAge);
        if (expired) {
          setQueue((prev) =>
            prev.filter((a) => a.timestamp !== expired.timestamp),
          );
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [queue]);

  const icons: Record<Alert['variant'], React.ReactNode> = {
    success: '🎉',
    error: '⚠️',
    warning: '⚠️',
    info: '💡',
    fire: '🔥',
    hi: '👋',
  };

  return (
    <Context.Provider value={{ queue, enqueue }}>
      <Box
        sx={{
          position: 'fixed',
          bottom: '32px',
          right: '32px',
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <AnimatePresence>
          {queue.map((alert) => (
            <motion.div
              key={`alert-${alert.timestamp}`}
              initial={{ opacity: 0, y: 32 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 32 }}
            >
              <AlertComponent
                icon={icons[alert.variant]}
                message={alert.message}
                onClose={() =>
                  setQueue((prev) =>
                    prev.filter((a) => a.timestamp !== alert.timestamp),
                  )
                }
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>
      {children}
    </Context.Provider>
  );
};

export default AlertProvider;

export const useAlert = () => {
  const { enqueue } = useContext(Context);

  const alert = (message: string, variant: Alert['variant']) => {
    enqueue({ message, variant });
  };

  return alert;
};
