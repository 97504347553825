import { List } from '@schema/list/list';
import { Order } from './order';
import { gql } from '@apollo/client';

export class OrderList extends List {
  orders!: Order[];

  static fragment = gql`
    fragment OrderListFragment on OrderList {
      total
      skip
      take
      orders {
        ...OrderFragment
      }
    }
  `;
}
