import { gql } from '@apollo/client';
import { Identified } from '@schema/identified/identified';

export class Variant extends Identified {
  size?: string;
  color?: string;
  price!: number;
  cost!: number;
  photo!: string;
  thumbnail!: string;
  photos!: string[];

  static fragment = gql`
    fragment VariantFragment on Variant {
      _id
      size
      color
      price
      cost
      photo
      thumbnail
      photos
    }
  `;
}
