import { motion } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export interface ArriveProps {
  initialScale?: number;
  duration?: number;
}

const Arrive: FC<PropsWithChildren<ArriveProps>> = ({
  children,
  initialScale = 0.9,
  duration = 0.22,
}) => {
  return (
    <motion.div
      whileInView={{
        scale: 1,
        opacity: 1,
      }}
      initial={{
        scale: initialScale,
        opacity: 0,
      }}
      transition={{ duration }}
    >
      {children}
    </motion.div>
  );
};

export default Arrive;
