import { gql } from '@apollo/client';
import { Identified } from '@schema/identified/identified';
import { User } from '@schema/user/types/user';
import { Balance } from './balance';
import { ExternalAccount } from './externalAccounts';

export class Team extends Identified {
  name!: string;
  tag!: string;
  league?: {
    _id: string;
    name: string;
    color: string;
  } | null;
  coach?: User | null;
  color!: string;
  onboarded!: boolean;

  static fragment = gql`
    fragment TeamFragment on Team {
      _id
      name
      tag

      onboarded
      league {
        _id
        name
      }
      coach {
        ...UserFragment
      }
      color
    }
  `;
}

export class TeamDetail extends Team {
  balance!: Balance;
  account!: string;
  externalAccounts!: ExternalAccount[];

  static fragment = gql`
    fragment TeamDetailFragment on Team {
      # Base fields
      _id
      name
      tag
      account
      onboarded
      league {
        _id
        name
      }
      coach {
        ...UserFragment
      }
      color
      # Detailed fields
      account
      balance {
        ...BalanceFragment
      }
      externalAccounts {
        ...ExternalAccountFragment
      }
    }
  `;
}
