import Icon, { CoreIcon } from '@components/display/Icon';
import BasePage from '@components/layout/BasePage';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { OrderProductLine } from '@schema/order/types/order.product.line';
import { Team } from '@schema/team/types/team';
import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Item: FC<{ label: string; value: string; strong?: boolean }> = ({
  label,
  value,
  strong = false,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography level="body-md">
        {strong ? <strong>{label}</strong> : label}
      </Typography>
      <Typography level="body-md">
        {strong ? <strong>{value}</strong> : value}
      </Typography>
    </Box>
  );
};

const DonationLineItem: FC<{ team: Team; amount: number }> = ({
  team,
  amount,
}) => {
  const { palette, radius } = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          height: '112px',
          width: '112px',
          background: palette.background.level1,
          borderRadius: radius.md,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0.3,
        }}
      >
        <CoreIcon.Donation fontSize="1.5rem" />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography level="title-md">{`Donation to ${team.name}`}</Typography>
        <Item
          label="Amount"
          value={new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(Math.round(amount / 100))}
        />
      </Box>
    </Box>
  );
};

const Line: FC<{ line: OrderProductLine }> = ({ line }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <img
        style={{
          height: '112px',
          width: '112px',
        }}
        src={line.product.photo}
        alt={line.product.name}
      />
      <Box sx={{ flex: 1 }}>
        <Typography level="title-md">{line.product.name}</Typography>
        {line.variant.size && <Item label="Size" value={line.variant.size} />}
        {line.variant.color && (
          <Item label="Color" value={line.variant.color} />
        )}
        <Item label="Quantity" value={line.quantity.toString()} />
        <Item
          label="Price"
          value={new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(Math.round(line.variant.price / 100))}
        />
      </Box>
    </Box>
  );
};

const OrderDetail: FC = () => {
  const alert = useAlert();
  const nav = useNavigate();
  const { id } = useParams();

  const { data, loading } = schema.order.read({
    variables: { id: id! },
    onError: () => {
      alert("Hmm. We couldn't find that order.", 'error');
      nav('/admin/orders');
    },
  });

  const order = data?.orderRead ?? null;

  const productSubtotal = order?.products.reduce(
    (acc, line) => acc + line.variant.price * line.quantity,
    0,
  );

  const donationSubtotal = order?.donations.reduce(
    (acc, donation) => acc + donation.amount,
    0,
  );

  const subtotal = (productSubtotal ?? 0) + (donationSubtotal ?? 0);

  return (
    <BasePage
      // action={
      //   <Stack direction="row" gap={1}>
      //     <Button endDecorator={<Icon.ArrowRight />} variant="outlined">
      //       View in Printful
      //     </Button>
      //     <Button endDecorator={<Icon.ArrowRight />} variant="outlined">
      //       View in Stripe
      //     </Button>
      //   </Stack>
      // }
      back={{
        label: 'Orders',
        path: '/admin/orders',
      }}
      title="Order Detail"
      loading={loading}
      maxWidth="sm"
    >
      {order && (
        <Box>
          <Divider />
          <Box p={2} />
          <Box>
            <Stack gap={2}>
              {order.donations.map((donation, i) => (
                <DonationLineItem
                  key={`dl-${i}`}
                  team={donation.team}
                  amount={donation.amount}
                />
              ))}
              {order.products.map((line, i) => (
                <Line key={`pl-${i}`} line={line} />
              ))}
            </Stack>
          </Box>
          <Box p={2} />
          <Divider />
          <Box p={1} />
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <Box sx={{ width: '112px' }} />
            <Box sx={{ flex: 1 }}>
              <Item
                label="Subtotal"
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Math.round(subtotal / 100))}
              />
              <Item
                label="Shipping"
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Math.round((order.amountBilled - subtotal) / 100))}
              />
              <Item
                strong
                label="Total"
                value={new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(Math.round(order.amountBilled / 100))}
              />
              {order.shippingAddress && (
                <Box>
                  <Box p={2} />
                  <Typography>
                    <strong>Shipping Address</strong>
                  </Typography>
                  <Typography>{order.shippingAddress.line1}</Typography>
                  <Typography>{order.shippingAddress.line2}</Typography>
                  <Typography>
                    {order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                    {order.shippingAddress.postalCode}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box p={1} />
          <Divider />
        </Box>
      )}
    </BasePage>
  );
};

export default OrderDetail;
