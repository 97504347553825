import { gql } from '@apollo/client';

export namespace AdminTypes {
  export namespace CacheClear {
    export const mutation = gql`
      mutation CacheClear {
        cacheClear
      }
    `;

    export type Variables = {};

    export type Response = {
      cacheClear: boolean;
    };
  }

  export namespace DbStats {
    export const query = gql`
      query DbStats {
        dbStats
      }
    `;

    export type Variables = {};

    export type Response = {
      dbStats: Record<string, unknown>;
    };
  }

  export namespace CacheStats {
    export const query = gql`
      query CacheStats {
        cacheStats
      }
    `;

    export type Variables = {};

    export type Response = {
      cacheStats: Record<string, unknown>;
    };
  }

  export namespace Sync {
    export const mutation = gql`
      mutation Sync {
        sync
      }
    `;

    export type Variables = {};

    export type Response = {
      sync: boolean;
    };
  }
}
