import Collapse from '@components/layout/Collapse';
import { AspectRatio, Box, Skeleton, Stack, Typography } from '@mui/joy';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import React, { FC, useState } from 'react';

export interface ProductCardProps {
  product: Product | null;
  onClick: () => void;
}

const ProductCard: FC<ProductCardProps> = ({ product, onClick }) => {
  const [variant, setVariant] = useState<Variant | null>(
    product?.variants[0] ?? null,
  );
  const [hoverVariant, setHoverVariant] = useState<Variant | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [hovered, setHovered] = useState(false);

  const price = !product
    ? ''
    : product.priceMax === product.priceMin
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Math.round(product.priceMax / 100))
    : `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(Math.round(product.priceMin / 100))} - ${new Intl.NumberFormat(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        },
      ).format(Math.round(product.priceMax / 100))}`;

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <AspectRatio ratio={1}>
        {product && variant ? (
          <Skeleton loading={!imageLoaded}>
            <img
              style={{
                padding: '12px',
              }}
              src={(hoverVariant || variant).photo}
              alt={product.name}
              onLoad={() => setImageLoaded(true)}
            />
          </Skeleton>
        ) : (
          <Skeleton variant="rectangular" />
        )}
      </AspectRatio>
      <Box p={1} />
      <Box>
        {product && (
          <Collapse open={hovered && product.colors.length > 1}>
            <Stack direction="row">
              {product.colors.map((color) => {
                const match = product.variants.find((v) => v.color === color);

                if (!match) return null;
                else
                  return (
                    <img
                      key={color}
                      src={match.photo}
                      alt={product.name}
                      onMouseEnter={() => setHoverVariant(match)}
                      onMouseLeave={() => setHoverVariant(null)}
                      style={{
                        height: '32px',
                        width: '32px',
                        cursor: 'pointer',
                      }}
                    />
                  );
              })}
            </Stack>
          </Collapse>
        )}
        <Typography level="title-md">
          <Skeleton loading={!product}>
            {product?.name ?? 'Loading...'}
          </Skeleton>
        </Typography>
        <Typography>{price}</Typography>
        <Typography>
          <Skeleton loading={!product}>
            {!product
              ? 'Loading...'
              : product.colors.length > 1
              ? `${product.colors.length} colors`
              : '1 color'}
          </Skeleton>
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductCard;
