import AuthForm from '@components/auth/AuthForm';
import Guard from '@components/auth/Guard';
import Page from '@components/layout/Page';
import Account from '@scenes/Account';
import Admin from '@scenes/Admin';
import Dashboard from '@scenes/Dashboard';
import Landing from '@scenes/Landing';
import NotFound from '@scenes/NotFound';
import Order from '@scenes/Order';
import Teams from '@scenes/Teams';
import { Role } from 'auth/role';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

const App: FC = () => {
  return (
    <Page>
      <Routes>
        <Route index element={<Landing />} />
        <Route path="signin" element={<AuthForm mode="signin" />} />
        <Route path="signup" element={<AuthForm mode="signup" />} />
        <Route
          path="order/:id"
          element={
            <Guard>
              <Order />
            </Guard>
          }
        />
        <Route
          path="teams/*"
          element={
            <Guard>
              <Teams />
            </Guard>
          }
        />
        <Route
          path="account"
          element={
            <Guard allowAnonymous={false}>
              <Account />
            </Guard>
          }
        />
        <Route
          path="dashboard/*"
          element={
            <Guard allowAnonymous={false}>
              <Dashboard />
            </Guard>
          }
        />
        <Route
          path="admin/*"
          element={
            <Guard allowAnonymous={false} role={Role.Owner}>
              <Admin />
            </Guard>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Page>
  );
};

export default App;
