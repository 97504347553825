import { Box, BoxProps } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';

type Width = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface SectionProps {
  maxWidth?: Width | number;
  sx?: {
    container?: BoxProps['sx'];
    content?: BoxProps['sx'];
  };
}

const Section: FC<PropsWithChildren<SectionProps>> = ({
  maxWidth = 'md',
  sx,
  children,
}) => {
  const widths: Record<Width, number> = {
    xs: 600,
    sm: 960,
    md: 1280,
    lg: 1920,
    xl: 2560,
  };

  const pxWidth = typeof maxWidth === 'number' ? maxWidth : widths[maxWidth];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 3,
        ...sx?.container,
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: `${pxWidth}px`,
          width: '100%',
          position: 'relative',
          ...sx?.content,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Section;
