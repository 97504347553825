import Logo from '@components/display/Logo';
import { Box, useTheme } from '@mui/joy';
import React, { FC } from 'react';
import AccountButton from './components/AccountButton';
import { useNavigate } from 'react-router-dom';
import AdminButton from './components/AdminButton';
import CoachButton from './components/CoachButton';
import CartButton from './components/CartButton';
import Home from './components/Home';

const Top: FC = () => {
  const { palette } = useTheme();
  const nav = useNavigate();
  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${palette.divider}`,
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => nav('/')}
      >
        <Logo height={40} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          paddingRight: 1,
        }}
      >
        <Home />
        <CartButton />
        <CoachButton />
        <AdminButton />
        <AccountButton />
      </Box>
    </Box>
  );
};

export default Top;
