import CartDrawer from '@components/display/CartDrawer';
import { CoreIcon } from '@components/display/Icon';
import { Badge, Button, IconButton } from '@mui/joy';
import React, { FC } from 'react';

const CartButton: FC = () => {
  return (
    <CartDrawer>
      {({ onClick, loading, total }) => (
        <Badge
          slotProps={{
            badge: {
              sx: {
                transform: 'translate(6px, -3px)',
              },
            },
          }}
          badgeContent={total}
          color="primary"
        >
          <Button
            sx={{
              padding: 1,
              paddingLeft: 2,
              paddingRight: 2,
            }}
            color="neutral"
            variant="plain"
            onClick={onClick}
            startDecorator={<CoreIcon.Bag />}
          >
            Bag
          </Button>
        </Badge>
      )}
    </CartDrawer>
  );
};

export default CartButton;
