import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './root';
import AuthProvider from '@providers/AuthProvider';
import ApiProvider from '@providers/ApiProvider';
import ClaimsProvider from '@providers/ClaimsProvider';
import ThemeProvider from '@providers/ThemeProvider';
import AlertProvider from '@providers/AlertProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <AlertProvider>
          <ApiProvider>
            <AuthProvider>
              <ClaimsProvider>
                <App />
              </ClaimsProvider>
            </AuthProvider>
          </ApiProvider>
        </AlertProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
