import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBZ9NogBQdlN0dHr1uhq0Zg65egL1w40tM',
  authDomain: 'platform-423505.firebaseapp.com',
  projectId: 'platform-423505',
  storageBucket: 'platform-423505.appspot.com',
  messagingSenderId: '927877438203',
  appId: '1:927877438203:web:0727b9a9a97f3e1badee5e',
  measurementId: 'G-E9GWW92Z44',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
