import BasePage from '@components/layout/BasePage';
import Tabbed from '@components/layout/Tabbed';
import schema from '@schema/index';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import TeamDetails from './components/TeamDetails';

const TeamDetail: FC = () => {
  const { id } = useParams();

  const { data, loading } = schema.team.readDetail({
    variables: {
      id: id!,
    },
  });

  const team = data?.teamRead ?? null;

  return (
    <BasePage
      back={{ label: 'Teams', path: '/admin/teams' }}
      title={team?.name ?? ''}
      loading={loading}
    >
      <Tabbed
        tabKey="td"
        padding={{
          top: 1.5,
          right: 12,
        }}
      >
        {{
          Details: {
            label: 'Details',
            content: team ? <TeamDetails team={team} /> : null,
          },
          Orders: { label: 'Orders', content: 'Orders' },
        }}
      </Tabbed>
    </BasePage>
  );
};

export default TeamDetail;
