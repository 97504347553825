import { gql } from '@apollo/client';

export class OrderShipmentItem {
  /**
   * Line item ID
   */
  itemId!: number;

  /**
   * Quantity of items in this shipment
   */
  quantity!: number;

  /**
   * A boolean indicating that the pickup stage of this item's fulfillment has been completed
   */
  picked!: boolean;

  /**
   * A boolean indicting that the item has been printed, sublimated or sewed.
   */
  printed!: boolean;

  static fragment = gql`
    fragment OrderShipmentItemFragment on OrderShipmentItem {
      itemId
      quantity
      picked
      printed
    }
  `;
}

export class OrderShipment {
  /**
   * Shipment ID
   */
  id!: number;

  /**
   * Carrier name
   */
  carrier!: string;

  /**
   * Delivery service name
   */
  service!: string;

  /**
   * Shipment tracking number
   */
  trackingNumber?: string;

  /**
   * Shipment tracking URL
   */
  trackingUrl?: string;

  /**
   * Shipping time
   */
  created!: number;

  /**
   * Ship date
   */
  shipDate!: string;

  /**
   * Ship time in unix timestamp
   */
  shippedAt!: string;

  /**
   * Whether this is a reshipment
   */
  reshipment!: boolean;

  /**
   * Array of items in this shipment
   */
  items!: OrderShipmentItem[];

  static fragment = gql`
    fragment OrderShipmentFragment on OrderShipment {
      id
      carrier
      service
      trackingNumber
      trackingUrl
      created
      shipDate
      shippedAt
      reshipment
      items {
        ...OrderShipmentItemFragment
      }
    }
  `;
}
