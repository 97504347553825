import Icon from '@components/display/Icon';
import { Box, Button, Grid, Typography } from '@mui/joy';
import React, { FC, ReactNode } from 'react';

export interface FeatureCardProps {
  icon: ReactNode;
  heading: string;
  description: string;
  call: string;
  onClick: () => void;
}

const FeatureCard: FC<FeatureCardProps> = ({
  icon,
  heading,
  description,
  call,
  onClick,
}) => {
  return (
    <Grid xs={12} sm={12} md={4}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            fontSize: '2rem',
          }}
        >
          {icon}
        </Box>
        <Box p={1} />
        <Typography level="title-lg">{heading}</Typography>
        <Box p={0.5} />
        <Typography level="body-lg">{description}</Typography>
        <Box p={2} sx={{ flex: 1 }} />
        <Button
          onClick={onClick}
          variant="plain"
          endDecorator={<Icon.ArrowRight />}
          size="sm"
          color="primary"
        >
          {call}
        </Button>
      </Box>
    </Grid>
  );
};

export default FeatureCard;
