import { Identified } from '@schema/identified/identified';
import { Variant } from './variant';
import { Team } from '@schema/team/types/team';
import { League } from '@schema/league/types/league';
import { gql } from '@apollo/client';

export class Product extends Identified {
  name!: string;
  photo!: string;
  league!: League | null;
  team!: Team | null;
  description?: string;
  colors!: string[];
  sizes!: string[];
  priceMin!: number;
  priceMax!: number;
  costMin!: number;
  costMax!: number;
  variants!: Variant[];

  static getMargins(product: Product) {
    const margin = product.priceMax - product.costMin;
    const marginPercent = (margin / product.priceMax) * 100;
    return { margin, marginPercent };
  }

  static fragment = gql`
    fragment ProductFragment on Product {
      _id
      name
      photo
      league {
        ...LeagueFragment
      }
      team {
        ...TeamFragment
      }
      description
      colors
      sizes
      priceMin
      priceMax
      costMin
      costMax
      variants {
        ...VariantFragment
      }
    }
  `;
}
