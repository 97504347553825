import { List } from '@schema/list/list';
import { Team } from './team';
import { gql } from '@apollo/client';

export class TeamList extends List {
  teams!: Team[];

  static fragment = gql`
    fragment TeamListFragment on TeamList {
      total
      skip
      take
      teams {
        ...TeamFragment
      }
    }
  `;
}
