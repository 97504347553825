import Section from '@components/layout/Section';
import { Box, Grid } from '@mui/joy';
import React, { FC } from 'react';
import FeatureCard, { FeatureCardProps } from './components/FeatureCard';
import Icon, { CoreIcon } from '@components/display/Icon';

const cards: Omit<FeatureCardProps, 'onClick'>[] = [
  {
    icon: <CoreIcon.Product />,
    heading: 'Sell Custom Apparel',
    description:
      'Our in house design team will help you create custom apparel for your team.',
    call: 'Get Started',
  },
  {
    icon: <CoreIcon.Donation />,
    heading: 'Collect Donations',
    description: 'Easily collect donations from your team and supporters.',
    call: 'Get Started',
  },
  {
    icon: <Icon.Heart />,
    heading: 'Engage Supporters',
    description:
      'Build stronger connections – rally your community behind your team.',
    call: 'Get Started',
  },
];

export interface FeaturesProps {
  getStarted: () => void;
}

const Features: FC<FeaturesProps> = ({ getStarted }) => {
  return (
    <Section>
      <Box sx={{ height: '96px' }} />
      <Grid container spacing={6} alignItems="stretch">
        {cards.map((card, index) => (
          <FeatureCard
            key={`feature-card-${index}`}
            {...card}
            onClick={getStarted}
          />
        ))}
      </Grid>
      <Box sx={{ height: '96px' }} />
    </Section>
  );
};

export default Features;
