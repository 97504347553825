import { gql } from '@apollo/client';
import { Product } from './product';
import { League } from '@schema/league/types/league';
import { Team } from '@schema/team/types/team';
import { Variant } from './variant';
import { User } from '@schema/user/types/user';
import { ProductList as List } from './product.list';
import { ProductFilter } from './product.filter';
import { ProductUpdate as Update } from './product.update';

export namespace ProductTypes {
  export namespace ProductRead {
    export const query = gql`
      ${Product.fragment}
      ${League.fragment}
      ${Team.fragment}
      ${Variant.fragment}
      ${User.fragment}
      query ProductRead($id: String!) {
        productRead(id: $id) {
          ...ProductFragment
        }
      }
    `;

    export type Result = {
      productRead: Product;
    };

    export type Variables = {
      id: string;
    };
  }

  export namespace ProductList {
    export const query = gql`
      ${List.fragment}
      ${Product.fragment}
      ${League.fragment}
      ${Team.fragment}
      ${Variant.fragment}
      ${User.fragment}
      query ProductList($filter: ProductFilter!, $skip: Int!, $take: Int!) {
        productList(filter: $filter, skip: $skip, take: $take) {
          ...ProductListFragment
        }
      }
    `;

    export type Result = {
      productList: List;
    };

    export type Variables = {
      filter: ProductFilter;
      skip: number;
      take: number;
    };
  }

  export namespace ProductCount {
    export const query = gql`
      query ProductCount($filter: ProductFilter!) {
        productCount(filter: $filter)
      }
    `;

    export type Result = {
      productCount: number;
    };

    export type Variables = {
      filter: ProductFilter;
    };
  }

  export namespace ProductUpdate {
    export const mutation = gql`
      ${Product.fragment}
      ${League.fragment}
      ${Team.fragment}
      ${Variant.fragment}
      ${User.fragment}
      mutation ProductUpdate($id: String!, $data: ProductUpdate!) {
        productUpdate(id: $id, data: $data) {
          ...ProductFragment
        }
      }
    `;

    export type Result = {
      productUpdate: Product;
    };

    export type Variables = {
      id: string;
      data: Update;
    };
  }
}
