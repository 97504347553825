import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { CartTypes } from '@schema/cart/types';
import { Cart } from '@schema/cart/types/cart';
import schema from '@schema/index';
import React, { FC, ReactNode, useState } from 'react';
import Icon, { CoreIcon } from '../Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useAuth } from '@providers/AuthProvider';
import Empty from '../Empty';
import ProductLineInput from './components/ProductLineInput';
import DonationLineInput from './components/DonationLineInput';

export interface CartDrawerProps {
  children: (provided: {
    onClick: () => void;
    loading: boolean;
    total: number;
  }) => ReactNode;
}

const CartDrawer: FC<CartDrawerProps> = ({ children }) => {
  const { palette } = useTheme();
  const { data, loading } = schema.cart.read();
  const { user } = useAuth();

  const [open, setOpen] = useState(false);

  const cart: Cart = data?.cartRead || {
    _id: user?.uid ?? '',
    donations: [],
    products: [],
  };

  const [handleCheckout, { loading: checkoutLoading }] = schema.cart.checkout({
    fetchPolicy: 'network-only',
    variables: {
      data: {
        products: cart.products.map((p) => ({
          team: p.team._id,
          product: p.product._id,
          variant: p.variant._id,
          quantity: p.quantity,
        })),
        donations: cart.donations.map((d) => ({
          team: d.team?._id,
          amount: d.amount,
          isCustom: d.isCustom === true,
        })),
      },
    },
    onCompleted: (res) => {
      const url = res.checkout;
      window.location.href = url;
    },
  });

  const [handleWrite, { loading: writeLoading }] = schema.cart.write({
    refetchQueries: [CartTypes.CartRead.query],
  });

  const total = cart.products.length + cart.donations.length;

  const onClick = () => {
    setOpen(true);
  };

  const changeProductQty = (index: number, qty: number | null) => {
    try {
      const newLines = [...cart.products];

      if (qty === 0) {
        newLines.splice(index, 1);
      } else {
        newLines[index] = {
          ...newLines[index],
          quantity: qty,
        };
      }

      handleWrite({
        variables: {
          data: {
            donations: cart.donations.map((d) => ({
              team: d.team._id,
              amount: d.amount,
              isCustom: d.isCustom === true,
            })),
            products: newLines.map((p) => ({
              team: p.team._id,
              product: p.product._id,
              variant: p.variant._id,
              quantity: p.quantity,
            })),
          },
        },
        optimisticResponse: {
          cartWrite: {
            ...cart,
            products: newLines,
          },
        },
      });
    } catch (e) {
      console.log('error while changing product quantity');
      console.error(e);
    }
  };

  const changeDonationAmount = (
    index: number,
    amount: number | null,
    isCustom: boolean,
  ) => {
    try {
      const newDonations = [...cart.donations];

      if (amount === 0) {
        return;
      } else {
        newDonations[index] = {
          ...newDonations[index],
          amount: amount,
          isCustom,
        };
      }

      handleWrite({
        variables: {
          data: {
            donations: newDonations.map((p) => ({
              team: p.team._id,
              amount: p.amount,
              isCustom: p.isCustom === true,
            })),
            products: cart.products.map((d) => ({
              team: d.team._id,
              product: d.product._id,
              variant: d.variant._id,
              quantity: d.quantity,
            })),
          },
        },
        optimisticResponse: {
          cartWrite: {
            ...cart,
            donations: newDonations,
          },
        },
      });
    } catch (e) {
      console.log('error while changing donation amount');
      console.error(e);
    }
  };

  const getTotal = (): number => {
    let runningTotal = 0;

    cart.products.forEach((product) => {
      runningTotal += Math.round(
        product.variant.price * (product.quantity ?? 0),
      );
    });

    cart.donations.forEach((donation) => {
      runningTotal += donation.amount ?? 0;
    });

    return isNaN(runningTotal) ? 0 : runningTotal;
  };

  const cashTotal = getTotal();

  return (
    <>
      {children({
        onClick,
        loading,
        total,
      })}
      <Drawer
        slotProps={{
          content: {
            sx: {
              minWidth: '500px',
            },
          },
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            background: palette.background.body,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography level="h3">Your Bag</Typography>
            <IconButton onClick={() => setOpen(false)} size="lg">
              <Icon.X />
            </IconButton>
          </Box>
          <Divider />
          {total === 0 && !loading ? (
            <Empty message="Your bag is empty" />
          ) : (
            <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                }}
              >
                {cart.donations.map((donation, i) => (
                  <>
                    <DonationLineInput
                      key={`donation-${i}`}
                      donation={donation}
                      onChange={(qty, ic) => changeDonationAmount(i, qty, ic)}
                      remove={() => {
                        const newDonations = [...cart.donations];
                        newDonations.splice(i, 1);
                        handleWrite({
                          variables: {
                            data: {
                              donations: newDonations.map((d) => ({
                                team: d.team._id,
                                amount: d.amount,
                                isCustom: d.isCustom === true,
                              })),
                              products: cart.products.map((p) => ({
                                team: p.team._id,
                                product: p.product._id,
                                variant: p.variant._id,
                                quantity: p.quantity,
                              })),
                            },
                          },
                          optimisticResponse: {
                            cartWrite: {
                              ...cart,
                              donations: newDonations,
                            },
                          },
                        });
                      }}
                    />
                  </>
                ))}
                {cart.products.map((product, i) => (
                  <ProductLineInput
                    key={`product-${i}`}
                    productLine={product}
                    onChange={(qty) => changeProductQty(i, qty)}
                    remove={() => {
                      const newLines = [...cart.products];
                      newLines.splice(i, 1);
                      handleWrite({
                        variables: {
                          data: {
                            donations: cart.donations.map((d) => ({
                              team: d.team._id,
                              amount: d.amount,
                              isCustom: d.isCustom === true,
                            })),
                            products: newLines.map((p) => ({
                              team: p.team._id,
                              product: p.product._id,
                              variant: p.variant._id,
                              quantity: p.quantity,
                            })),
                          },
                        },
                        optimisticResponse: {
                          cartWrite: {
                            ...cart,
                            products: newLines,
                          },
                        },
                      });
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {cart.donations.length > 0 || cart.products.length > 0 ? (
            <>
              <Box sx={{ p: 1, display: 'flex' }}>
                <Button
                  fullWidth
                  variant="plain"
                  startDecorator={<Icon.ArrowLeft />}
                  onClick={() => setOpen(false)}
                >
                  Keep Shoping
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Tooltip
                  title={
                    cart.products.some((p) => !p.quantity)
                      ? 'Please add a quantity to all products or remove them'
                      : cart.donations.some((d) => !d.amount)
                      ? 'Please add a donation amount to all donations or remove them'
                      : cart.donations.some((d) => (d.amount ?? 0) < 1000)
                      ? 'Minimum donation amount is $10'
                      : ''
                  }
                >
                  <Box>
                    <Button
                      fullWidth
                      size="lg"
                      endDecorator={<CoreIcon.Bag />}
                      loading={writeLoading || checkoutLoading}
                      disabled={
                        cart.products.some((p) => !p.quantity) ||
                        cart.donations.some((d) => !d.amount) ||
                        cart.donations.some((d) => (d.amount ?? 0) < 1000)
                      }
                      onClick={() => {
                        handleCheckout();
                      }}
                    >
                      Checkout
                    </Button>
                  </Box>
                </Tooltip>
                <AnimatePresence mode="popLayout">
                  <motion.div
                    key={`t-${cashTotal}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '16px',
                    }}
                  >
                    <Typography level="body-sm">
                      Subtotal:{' '}
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(Math.round(cashTotal / 100))}
                    </Typography>
                  </motion.div>
                </AnimatePresence>
              </Box>
            </>
          ) : null}
        </Box>
      </Drawer>
    </>
  );
};

export default CartDrawer;
