import Icon, { CoreIcon } from '@components/display/Icon';
import { Button, Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useAuth } from '@providers/AuthProvider';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountButton: FC = () => {
  const { user, signout } = useAuth();
  const { pathname } = useLocation();
  const nav = useNavigate();

  if (!user) return null;
  else if (user.isAnonymous)
    return (
      <Button
        variant="plain"
        sx={{
          padding: 1,
          paddingLeft: 2,
          paddingRight: 2,
        }}
        endDecorator={<CoreIcon.User />}
        color="neutral"
        onClick={() => {
          nav('/signin', { state: { from: pathname } });
        }}
      >
        Sign In
      </Button>
    );

  const active = pathname.startsWith('/account');

  return (
    <Button
      onClick={() => nav('/account')}
      color={active ? 'primary' : 'neutral'}
      startDecorator={<CoreIcon.User />}
      variant="plain"
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      {user.displayName ?? user.email ?? user.phoneNumber ?? 'Account'}
    </Button>
  );
};

export default AccountButton;
