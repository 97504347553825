import { gql } from '@apollo/client';
import { User } from './user';
import { UserList } from './user.list';
import { UserCreate } from './user.create';
import { UserUpdate } from './user.update';

export namespace UserTypes {
  export namespace Read {
    export const query = gql`
      ${User.fragment}
      query UserRead($uid: String!) {
        userRead(uid: $uid) {
          ...UserFragment
        }
      }
    `;

    export type Result = {
      userRead: User;
    };

    export type Variables = {
      uid: string;
    };
  }

  export namespace List {
    export const query = gql`
      ${User.fragment}
      query UserList($pageToken: String) {
        userList(pageToken: $pageToken) {
          pageToken
          users {
            ...UserFragment
          }
        }
      }
    `;

    export type Result = {
      userList: UserList;
    };

    export type Variables = {
      pageToken?: string;
    };
  }

  export namespace Create {
    export const mutation = gql`
      ${User.fragment}
      mutation UserCreate($data: UserCreate!) {
        userCreate(data: $data) {
          ...UserFragment
        }
      }
    `;

    export type Result = {
      userCreate: User;
    };

    export type Variables = {
      data: UserCreate;
    };
  }

  export namespace Update {
    export const mutation = gql`
      ${User.fragment}
      mutation UserUpdate($data: UserUpdate!, $uid: String!) {
        userUpdate(data: $data, id: $uid) {
          ...UserFragment
        }
      }
    `;

    export type Result = {
      userUpdate: User;
    };

    export type Variables = {
      uid: string;
      data: UserUpdate;
    };
  }
}
