import { useTheme } from '@mui/joy';
import { client } from '@providers/ApiProvider';
import { typography } from '@providers/ThemeProvider';
import { TeamTypes } from '@schema/team/types';
import { loadConnectAndInitialize } from '@stripe/connect-js';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!publishableKey) {
  throw new Error('REACT_APP_STRIPE_PUBLISHABLE_KEY is required');
}

const fetchClientSecret = async (id: string) => {
  const { data, errors } = await client.mutate<
    TeamTypes.Session.Result,
    TeamTypes.Session.Variables
  >({
    mutation: TeamTypes.Session.mutation,
    variables: {
      team: id,
    },
    fetchPolicy: 'network-only',
  });

  if (errors && errors.length > 0) {
    throw new Error(errors[0].message);
  } else if (!data) {
    throw new Error('No data returned');
  } else {
    return data.teamSession;
  }
};

export const useConnect = (team: string) => {
  const { palette, fontFamily, radius, spacing } = useTheme();
  return loadConnectAndInitialize({
    publishableKey,
    fetchClientSecret: () => fetchClientSecret(team),
    appearance: {
      variables: {
        // Commonly used

        /**
         * The font family value used throughout embedded components. If an embedded component inherits a font-family value from an element on your site in which it’s placed, this setting overrides that inheritance.
         */
        fontFamily: fontFamily.display,

        /**
         * The baseline font size set on the embedded component root. This scales the value of other font size variables. This supports pixel values only ranging from 1px to 40px, 0.1em to 4em, and 0.1rem to 4rem.
         */
        // fontSizeBase: string;

        /**
         * The base spacing unit that derives all spacing values. Increase or decrease this value to make your layout more or less spacious. This supports pixel values only ranging from 8px to 20px.
         */
        // spacingUnit: string;

        /**
         * The general border radius used in embedded components. This sets the default border radius for all components. This supports pixel values only, with a maximum value of 24px.
         */
        borderRadius: radius.md,

        /**
         * The primary color used throughout embedded components. Set this to your primary brand color. This accepts hex values or RGB/HSL strings.
         */
        colorPrimary: palette.primary[500],

        /**
         * The background color for embedded components, including overlays, tooltips, and popovers. This accepts hex values or RGB/HSL strings.
         */
        colorBackground: palette.background.body,

        /**
         * The color used for regular text. This accepts hex values or RGB/HSL strings.
         */
        colorText: palette.text.primary,

        /**
         * The color used to indicate errors or destructive actions. This accepts hex values or RGB/HSL strings.
         */
        colorDanger: palette.danger[500],

        // Less commonly used

        // Primary Button
        /**
         * The color used as a background for primary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonPrimaryColorBackground: palette.primary[500],
        /**
         * The border color used for primary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonPrimaryColorBorder: palette.primary[500],
        /**
         * The text color used for primary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonPrimaryColorText: palette.common.white,

        // Secondary Button
        /**
         * The color used as a background for secondary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonSecondaryColorBackground: palette.neutral[50],
        /**
         * The color used as a border for secondary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonSecondaryColorBorder: palette.neutral[50],
        /**
         * The text color used for secondary buttons. This accepts hex values or RGB/HSL strings.
         */
        buttonSecondaryColorText: palette.text.primary,

        /**
         * The color used for secondary text. This accepts hex values or RGB/RGBA/HSL strings.
         */
        colorSecondaryText: palette.text.secondary,
        /**
         * The color used for primary actions and links. This accepts hex values or RGB/HSL strings.
         */
        actionPrimaryColorText: palette.primary[500],
        /**
         * The color used for secondary actions and links. This accepts hex values or RGB/HSL strings.
         */
        actionSecondaryColorText: palette.neutral[500],

        // Neutral Badge Colors
        /**
         * The background color used to represent neutral state or lack of state in status badges. This accepts hex values or RGB/HSL strings.
         */
        badgeNeutralColorBackground: palette.neutral[100],
        /**
         * The text color used to represent neutral state or lack of state in status badges. This accepts hex values or RGB/HSL strings.
         */
        badgeNeutralColorText: palette.text.primary,
        /**
         * The border color used to represent neutral state or lack of state in status badges. This accepts hex values or RGB/RGBA/HSL strings.
         */
        badgeNeutralColorBorder: palette.neutral[100],

        // Success Badge Colors
        /**
         * The background color used to reinforce a successful outcome in status badges. This accepts hex values or RGB/HSL strings.
         */
        badgeSuccessColorBackground: palette.success[500],
        /**
         * The text color used to reinforce a successful outcome in status badges. This accepts hex values or RGB/HSL strings.
         */
        badgeSuccessColorText: palette.common.white,
        /**
         * The border color used to reinforce a successful outcome in status badges. This accepts hex values or RGB/RGBA/HSL strings.
         */
        badgeSuccessColorBorder: palette.success[500],

        // Warning Badge Colors
        /**
         * The background color used in status badges to highlight things that might require action, but are optional to resolve. This accepts hex values or RGB/HSL strings.
         */
        badgeWarningColorBackground: palette.warning[500],
        /**
         * The text color used in status badges to highlight things that might require action, but are optional to resolve. This accepts hex values or RGB/HSL strings.
         */
        badgeWarningColorText: palette.common.white,
        /**
         * The border color used in status badges to highlight things that might require action, but are optional to resolve. This accepts hex values or RGB/RGBA/HSL strings.
         */
        badgeWarningColorBorder: palette.warning[500],

        // Danger Badge Colors
        /**
         * The background color used in status badges for high-priority, critical situations that the user must address immediately, and to indicate failed or unsuccessful outcomes. This accepts hex values or RGB/HSL strings.
         */
        badgeDangerColorBackground: palette.danger[500],
        /**
         * The text color used in status badges for high-priority, critical situations that the user must address immediately, and to indicate failed or unsuccessful outcomes. This accepts hex values or RGB/HSL strings.
         */
        badgeDangerColorText: palette.common.white,
        /**
         * The border color used in status badges for high-priority, critical situations that the user must address immediately, and to indicate failed or unsuccessful outcomes. This accepts hex values or RGB/RGBA/HSL strings.
         */
        badgeDangerColorBorder: palette.danger[500],

        // Background
        /**
         * The background color used when highlighting information, like the selected row on a table or particular piece of UI. This accepts hex values or RGB/HSL strings.
         */
        offsetBackgroundColor: palette.neutral[50],
        /**
         * The background color used for form items. This accepts hex values or RGB/HSL strings.
         */
        formBackgroundColor: palette.background.body,

        /**
         * The color used for borders throughout the component. This accepts hex values or RGB/RGBA/HSL strings.
         */
        colorBorder: palette.divider,

        // Form
        /**
         * The color used to highlight form items when focused. This accepts hex values or RGB/RGBA/HSL strings.
         */
        // formHighlightColorBorder: string;
        /**
         * The color used for to fill in form items like checkboxes, radio buttons and switches. This accepts hex values or RGB/HSL strings.
         */
        formAccentColor: palette.primary[500],

        // Border Sizing
        /**
         * The border radius used for buttons. This supports pixel values only.
         */
        buttonBorderRadius: radius.md,
        /**
         * The border radius used for form elements. This supports pixel values only, with a maximum value of 24px.
         */
        formBorderRadius: radius.md,
        /**
         * The border radius used for badges. This supports pixel values only, with a maximum value of 24px.
         */
        badgeBorderRadius: radius.sm,
        /**
         * The border radius used for overlays. This supports pixel values only, with a maximum value of 24px.
         */
        overlayBorderRadius: radius.md,

        // Font Sizing

        // Overlay
        /**
         * A z-index to use for the overlay throughout embedded components. Set this number to control the z-order of the overlay.
         */
        // overlayZIndex: number;

        // Body Typography
        /**
         * The font size for the medium body typography. Body typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        bodyMdFontSize: typography['body-md'].fontSize,
        /**
         * The font weight for the medium body typography. Body typography variables accept a valid font weight value.
         */
        bodyMdFontWeight: typography['body-md'].fontWeight.toString(),
        /**
         * The font size for the small body typography. Body typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        bodySmFontSize: typography['body-sm'].fontSize,
        /**
         * The font weight for the small body typography. Body typography variables accept a valid font weight value.
         */
        bodySmFontWeight: typography['body-sm'].fontWeight.toString(),

        // Heading Typography
        /**
         * The font size for the extra large heading typography. Heading typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        headingXlFontSize: typography.h4.fontSize,
        /**
         * The font weight for the extra large heading typography. Heading typography variables accept a valid font weight value.
         */
        headingXlFontWeight: typography.h4.fontWeight.toString(),
        /**
         * The text transform for the extra large heading typography. Heading typography variables accept a valid text transform value.
         */
        // headingXlTextTransform: string;
        /**
         * The font size for the large heading typography. Heading typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        headingLgFontSize: typography['title-lg'].fontSize,
        /**
         * The font weight for the large heading typography. Heading typography variables accept a valid font weight value.
         */
        headingLgFontWeight: typography['title-lg'].fontWeight.toString(),
        /**
         * The text transform for the large heading typography. Heading typography variables accept a valid text transform value.
         */
        // headingLgTextTransform: string;
        /**
         * The font size for the medium heading typography. Heading typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        headingMdFontSize: typography['title-md'].fontSize,
        /**
         * The font weight for the medium heading typography. Heading typography variables accept a valid font weight value.
         */
        headingMdFontWeight: typography['title-md'].fontWeight.toString(),
        /**
         * The text transform for the medium heading typography. Heading typography variables accept a valid text transform value.
         */
        // headingMdTextTransform: string;
        /**
         * The font size for the small heading typography. Heading typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        headingSmFontSize: typography['title-sm'].fontSize,
        /**
         * The font weight for the small heading typography. Heading typography variables accept a valid font weight value.
         */
        headingSmFontWeight: typography['title-sm'].fontWeight.toString(),
        /**
         * The text transform for the small heading typography. Heading typography variables accept a valid text transform value.
         */
        // headingSmTextTransform: string;
        /**
         * The font size for the extra small heading typography. Heading typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        headingXsFontSize: typography['title-sm'].fontSize,
        /**
         * The font weight for the extra small heading typography. Heading typography variables accept a valid font weight value.
         */
        headingXsFontWeight: typography['title-sm'].fontWeight.toString(),
        /**
         * The text transform for the extra small heading typography. Heading typography variables accept a valid text transform value.
         */
        // headingXsTextTransform: string;

        // Label Typography
        /**
         * The font size for the medium label typography. Label typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        labelMdFontSize: typography['body-sm'].fontSize,
        /**
         * The font weight for the medium label typography. Label typography variables accept a valid font weight value.
         */
        labelMdFontWeight: typography['body-sm'].fontWeight.toString(),
        /**
         * The text transform for the medium label typography. Label typography variables accept a valid text transform value.
         */
        // labelMdTextTransform: string;
        /**
         * The font size for the small label typography. Label typography variables accept a valid font size value ranging from 1px to 200px, 0.1em to 12em, and 0.1rem to 12rem.
         */
        labelSmFontSize: typography['body-xs'].fontSize,
        /**
         * The font weight for the small label typography. Label typography variables accept a valid font weight value.
         */
        labelSmFontWeight: typography['body-xs'].fontWeight.toString(),
        /**
         * The text transform for the small label typography. Label typography variables accept a valid text transform value.
         */
        // labelSmTextTransform: string;
      },
    },
  });
};
