import { Box, BoxProps } from '@mui/joy';
import React, { FC, PropsWithChildren } from 'react';

export interface CProps {
  sx?: BoxProps['sx'];
}

const C: FC<PropsWithChildren<CProps>> = ({ sx = {}, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default C;
