import { signInAnonymously, User } from 'firebase/auth';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { auth } from './config';
import { client } from '@providers/ApiProvider';

export interface AuthContext {
  user: User | null;
  loading: boolean;
  signout: () => void;
}

const Context = createContext<AuthContext>({
  loading: true,
  user: null,
  signout: () => {},
});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        signInAnonymously(auth);
      }
    });

    return () => unsubscribe();
  }, []);

  const signout = async () => {
    await auth.signOut();
    await client.clearStore();
  };

  if (!user) {
    return null;
  }

  return (
    <Context.Provider value={{ user, loading, signout }}>
      {children}
    </Context.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(Context);
