import Nav from '@components/layout/Nav';
import Section from '@components/layout/Section';
import { Box, Typography } from '@mui/joy';
import schema from '@schema/index';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Summary from './components/Summary';
import { Order } from '@schema/order/types/order';

export interface OrderProps {}

type State =
  | {
      type: 'loading';
    }
  | {
      type: 'error';
      error: Error;
    }
  | {
      type: 'success';
      order: Order;
    };

const maxAttempts = 20;

const OrderView: FC<OrderProps> = ({}) => {
  const { id } = useParams();

  const [state, setState] = useState<State>({ type: 'loading' });
  const [attempts, setAttempts] = useState(0);

  const { refetch } = schema.cart.read({
    fetchPolicy: 'network-only',
  });

  const [getOrder] = schema.order.readLazy({
    variables: { id: id! },
    onError: (error) => {
      if (attempts < maxAttempts) {
        setAttempts(attempts + 1);
      } else {
        setState({ type: 'error', error });
        refetch();
      }
    },
    onCompleted: (data) => {
      setState({ type: 'success', order: data.orderRead });
      refetch();
    },
  });

  useEffect(() => {
    if (state.type === 'loading') {
      const interval = setInterval(() => {
        getOrder();
      }, 500);

      return () => clearInterval(interval);
    }
  }, [state]);

  return (
    <Nav loading={state.type === 'loading'}>
      {state.type === 'loading' ? (
        <Box />
      ) : state.type === 'error' ? (
        <Typography color="danger">{state.error.message}</Typography>
      ) : (
        <Section maxWidth="xs">
          <Summary order={state.order} />
        </Section>
      )}
    </Nav>
  );
};

export default OrderView;
