import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import TeamHome from './components/TeamHome';
import TeamList from './components/TeamList';
import TeamProduct from './components/TeamProduct';

const Teams: FC = () => {
  return (
    <Routes>
      <Route index element={<TeamList />} />
      <Route path=":tag" element={<TeamHome />} />
      <Route path=":tag/:id" element={<TeamProduct />} />
    </Routes>
  );
};

export default Teams;
