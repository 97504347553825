import { gql } from '@apollo/client';
import { Team, TeamDetail } from './team';
import { TeamFilter } from './team.filter';
import { TeamCreate } from './team.create';
import { TeamUpdate } from './team.update';
import { TeamList } from './team.list';
import { User } from '@schema/user/types/user';
import { Balance } from './balance';
import { ExternalAccount } from './externalAccounts';

export namespace TeamTypes {
  export namespace Count {
    export const query = gql`
      query TeamCount($filter: TeamFilter!) {
        teamCount(filter: $filter)
      }
    `;

    export type Result = { teamCount: number };
    export type Variables = { filter: TeamFilter };
  }

  export namespace Read {
    export const query = gql`
      ${Team.fragment}
      ${User.fragment}
      query TeamRead($id: String!) {
        teamRead(id: $id) {
          ...TeamFragment
        }
      }
    `;

    export type Result = { teamRead: Team };
    export type Variables = { id: string };
  }

  export namespace ReadDetail {
    export const query = gql`
      ${TeamDetail.fragment}
      ${User.fragment}
      ${Balance.fragment}
      ${ExternalAccount.fragment}
      query TeamDetailRead($id: String!) {
        teamRead(id: $id) {
          ...TeamDetailFragment
        }
      }
    `;

    export type Result = { teamRead: TeamDetail };
    export type Variables = { id: string };
  }

  export namespace List {
    export const query = gql`
      ${TeamList.fragment}
      ${Team.fragment}
      ${User.fragment}
      query TeamList($filter: TeamFilter!, $skip: Int!, $take: Int!) {
        teamList(filter: $filter, skip: $skip, take: $take) {
          ...TeamListFragment
        }
      }
    `;

    export type Result = { teamList: TeamList };
    export type Variables = {
      filter: TeamFilter;
      skip: number;
      take: number;
    };
  }

  export namespace ListDetail {
    export const query = gql`
      ${TeamDetail.fragment}
      ${User.fragment}
      ${Balance.fragment}
      ${ExternalAccount.fragment}
      query TeamDetailList($filter: TeamFilter!, $skip: Int!, $take: Int!) {
        teamList(filter: $filter, skip: $skip, take: $take) {
          total
          skip
          take
          teams {
            ...TeamDetailFragment
          }
        }
      }
    `;

    export type Result = {
      teamList: {
        total: number;
        skip: number;
        take: number;
        teams: TeamDetail[];
      };
    };
    export type Variables = {
      filter: TeamFilter;
      skip: number;
      take: number;
    };
  }

  export namespace Create {
    export const mutation = gql`
      ${TeamDetail.fragment}
      ${User.fragment}
      ${Balance.fragment}
      ${ExternalAccount.fragment}
      mutation TeamCreate($data: TeamCreate!) {
        teamCreate(data: $data) {
          ...TeamDetailFragment
        }
      }
    `;

    export type Result = { teamCreate: Team };
    export type Variables = { data: TeamCreate };
  }

  export namespace Update {
    export const mutation = gql`
      ${TeamDetail.fragment}
      ${User.fragment}
      ${Balance.fragment}
      ${ExternalAccount.fragment}
      mutation TeamUpdate($id: String!, $data: TeamUpdate!) {
        teamUpdate(id: $id, data: $data) {
          ...TeamDetailFragment
        }
      }
    `;

    export type Result = { teamUpdate: Team };
    export type Variables = { id: string; data: TeamUpdate };
  }

  export namespace Session {
    export const mutation = gql`
      mutation TeamSession($team: String!) {
        teamSession(team: $team)
      }
    `;

    export type Result = {
      teamSession: string;
    };

    export type Variables = {
      team: string;
    };
  }
}
