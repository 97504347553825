import { gql } from '@apollo/client';
import { Order } from './order';
import { Address } from '@schema/address/types/address';
import { User } from '@schema/user/types/user';
import { OrderCosts } from './order.costs';
import { OrderProductLine } from './order.product.line';
import { Product } from '@schema/product/types/product';
import { Variant } from '@schema/product/types/variant';
import { OrderList as List } from './order.list';
import { OrderFilter } from './order.filter';
import { League } from '@schema/league/types/league';
import { Team } from '@schema/team/types/team';
import { OrderShipment, OrderShipmentItem } from './order.shipment';
import { OrderDonationLine } from './order.donation.line';

export namespace OrderTypes {
  export namespace OrderRead {
    export const query = gql`
      ${Order.fragment}
      ${User.fragment}
      ${Address.fragment}
      ${OrderDonationLine.fragment}
      ${OrderProductLine.fragment}
      ${OrderCosts.fragment}
      ${Product.fragment}
      ${Variant.fragment}
      ${League.fragment}
      ${User.fragment}
      ${Team.fragment}
      ${OrderShipment.fragment}
      ${OrderShipmentItem.fragment}
      query OrderRead($id: String!) {
        orderRead(id: $id) {
          ...OrderFragment
        }
      }
    `;

    export type Result = {
      orderRead: Order;
    };

    export type Variables = {
      id: string;
    };
  }

  export namespace OrderList {
    export const query = gql`
      ${List.fragment}
      ${Order.fragment}
      ${User.fragment}
      ${Address.fragment}
      ${OrderDonationLine.fragment}
      ${OrderProductLine.fragment}
      ${OrderCosts.fragment}
      ${Product.fragment}
      ${Variant.fragment}
      ${League.fragment}
      ${User.fragment}
      ${Team.fragment}
      ${OrderShipment.fragment}
      ${OrderShipmentItem.fragment}
      query OrderList($filter: OrderFilter!, $skip: Int!, $take: Int!) {
        orderList(filter: $filter, skip: $skip, take: $take) {
          ...OrderListFragment
        }
      }
    `;

    export type Result = {
      orderList: List;
    };

    export type Variables = {
      filter: OrderFilter;
      skip: number;
      take: number;
    };
  }

  export namespace OrderCount {
    export const query = gql`
      query OrderCount($filter: OrderFilter!) {
        orderCount(filter: $filter)
      }
    `;

    export type Result = {
      orderCount: number;
    };

    export type Variables = {
      filter: OrderFilter;
    };
  }
}
