import React, { FC } from 'react';

import * as Options from 'react-icons/fi';

const Icon = {
  Activity: Options.FiActivity,
  Airplay: Options.FiAirplay,
  AlertCircle: Options.FiAlertCircle,
  AlertOctagon: Options.FiAlertOctagon,
  AlertTriangle: Options.FiAlertTriangle,
  AlignCenter: Options.FiAlignCenter,
  AlignJustify: Options.FiAlignJustify,
  AlignLeft: Options.FiAlignLeft,
  AlignRight: Options.FiAlignRight,
  Anchor: Options.FiAnchor,
  Aperture: Options.FiAperture,
  Archive: Options.FiArchive,
  ArrowDown: Options.FiArrowDown,
  ArrowDownCircle: Options.FiArrowDownCircle,
  ArrowDownLeft: Options.FiArrowDownLeft,
  ArrowDownRight: Options.FiArrowDownRight,
  ArrowLeft: Options.FiArrowLeft,
  ArrowLeftCircle: Options.FiArrowLeftCircle,
  ArrowRight: Options.FiArrowRight,
  ArrowRightCircle: Options.FiArrowRightCircle,
  ArrowUp: Options.FiArrowUp,
  ArrowUpCircle: Options.FiArrowUpCircle,
  ArrowUpLeft: Options.FiArrowUpLeft,
  ArrowUpRight: Options.FiArrowUpRight,
  AtSign: Options.FiAtSign,
  Award: Options.FiAward,
  BarChart: Options.FiBarChart,
  BarChart2: Options.FiBarChart2,
  Battery: Options.FiBattery,
  BatteryCharging: Options.FiBatteryCharging,
  Bell: Options.FiBell,
  BellOff: Options.FiBellOff,
  Bluetooth: Options.FiBluetooth,
  Bold: Options.FiBold,
  Book: Options.FiBook,
  BookOpen: Options.FiBookOpen,
  Bookmark: Options.FiBookmark,
  Box: Options.FiBox,
  Briefcase: Options.FiBriefcase,
  Calendar: Options.FiCalendar,
  Camera: Options.FiCamera,
  CameraOff: Options.FiCameraOff,
  Cast: Options.FiCast,
  Check: Options.FiCheck,
  CheckCircle: Options.FiCheckCircle,
  CheckSquare: Options.FiCheckSquare,
  ChevronDown: Options.FiChevronDown,
  ChevronLeft: Options.FiChevronLeft,
  ChevronRight: Options.FiChevronRight,
  ChevronUp: Options.FiChevronUp,
  ChevronsDown: Options.FiChevronsDown,
  ChevronsLeft: Options.FiChevronsLeft,
  ChevronsRight: Options.FiChevronsRight,
  ChevronsUp: Options.FiChevronsUp,
  Chrome: Options.FiChrome,
  Circle: Options.FiCircle,
  Clipboard: Options.FiClipboard,
  Clock: Options.FiClock,
  Cloud: Options.FiCloud,
  CloudDrizzle: Options.FiCloudDrizzle,
  CloudLightning: Options.FiCloudLightning,
  CloudOff: Options.FiCloudOff,
  CloudRain: Options.FiCloudRain,
  CloudSnow: Options.FiCloudSnow,
  Code: Options.FiCode,
  Codepen: Options.FiCodepen,
  Codesandbox: Options.FiCodesandbox,
  Coffee: Options.FiCoffee,
  Columns: Options.FiColumns,
  Command: Options.FiCommand,
  Compass: Options.FiCompass,
  Copy: Options.FiCopy,
  CornerDownLeft: Options.FiCornerDownLeft,
  CornerDownRight: Options.FiCornerDownRight,
  CornerLeftDown: Options.FiCornerLeftDown,
  CornerLeftUp: Options.FiCornerLeftUp,
  CornerRightDown: Options.FiCornerRightDown,
  CornerRightUp: Options.FiCornerRightUp,
  CornerUpLeft: Options.FiCornerUpLeft,
  CornerUpRight: Options.FiCornerUpRight,
  Cpu: Options.FiCpu,
  CreditCard: Options.FiCreditCard,
  Crop: Options.FiCrop,
  Crosshair: Options.FiCrosshair,
  Database: Options.FiDatabase,
  Delete: Options.FiDelete,
  Disc: Options.FiDisc,
  Divide: Options.FiDivide,
  DivideCircle: Options.FiDivideCircle,
  DivideSquare: Options.FiDivideSquare,
  DollarSign: Options.FiDollarSign,
  Download: Options.FiDownload,
  DownloadCloud: Options.FiDownloadCloud,
  Dribbble: Options.FiDribbble,
  Droplet: Options.FiDroplet,
  Edit: Options.FiEdit,
  Edit2: Options.FiEdit2,
  Edit3: Options.FiEdit3,
  ExternalLink: Options.FiExternalLink,
  Eye: Options.FiEye,
  EyeOff: Options.FiEyeOff,
  Facebook: Options.FiFacebook,
  FastForward: Options.FiFastForward,
  Feather: Options.FiFeather,
  Figma: Options.FiFigma,
  File: Options.FiFile,
  FileMinus: Options.FiFileMinus,
  FilePlus: Options.FiFilePlus,
  FileText: Options.FiFileText,
  Film: Options.FiFilm,
  Filter: Options.FiFilter,
  Flag: Options.FiFlag,
  Folder: Options.FiFolder,
  FolderMinus: Options.FiFolderMinus,
  FolderPlus: Options.FiFolderPlus,
  Framer: Options.FiFramer,
  Frown: Options.FiFrown,
  Gift: Options.FiGift,
  GitBranch: Options.FiGitBranch,
  GitCommit: Options.FiGitCommit,
  GitMerge: Options.FiGitMerge,
  GitPullRequest: Options.FiGitPullRequest,
  Github: Options.FiGithub,
  Gitlab: Options.FiGitlab,
  Globe: Options.FiGlobe,
  Grid: Options.FiGrid,
  HardDrive: Options.FiHardDrive,
  Hash: Options.FiHash,
  Headphones: Options.FiHeadphones,
  Heart: Options.FiHeart,
  HelpCircle: Options.FiHelpCircle,
  Hexagon: Options.FiHexagon,
  Home: Options.FiHome,
  Image: Options.FiImage,
  Inbox: Options.FiInbox,
  Info: Options.FiInfo,
  Instagram: Options.FiInstagram,
  Italic: Options.FiItalic,
  Key: Options.FiKey,
  Layers: Options.FiLayers,
  Layout: Options.FiLayout,
  LifeBuoy: Options.FiLifeBuoy,
  Link: Options.FiLink,
  Link2: Options.FiLink2,
  Linkedin: Options.FiLinkedin,
  List: Options.FiList,
  Loader: Options.FiLoader,
  Lock: Options.FiLock,
  LogIn: Options.FiLogIn,
  LogOut: Options.FiLogOut,
  Mail: Options.FiMail,
  Map: Options.FiMap,
  MapPin: Options.FiMapPin,
  Maximize: Options.FiMaximize,
  Maximize2: Options.FiMaximize2,
  Meh: Options.FiMeh,
  Menu: Options.FiMenu,
  MessageCircle: Options.FiMessageCircle,
  MessageSquare: Options.FiMessageSquare,
  Mic: Options.FiMic,
  MicOff: Options.FiMicOff,
  Minimize: Options.FiMinimize,
  Minimize2: Options.FiMinimize2,
  Minus: Options.FiMinus,
  MinusCircle: Options.FiMinusCircle,
  MinusSquare: Options.FiMinusSquare,
  Monitor: Options.FiMonitor,
  Moon: Options.FiMoon,
  MoreHorizontal: Options.FiMoreHorizontal,
  MoreVertical: Options.FiMoreVertical,
  MousePointer: Options.FiMousePointer,
  Move: Options.FiMove,
  Music: Options.FiMusic,
  Navigation: Options.FiNavigation,
  Navigation2: Options.FiNavigation2,
  Octagon: Options.FiOctagon,
  Package: Options.FiPackage,
  Paperclip: Options.FiPaperclip,
  Pause: Options.FiPause,
  PauseCircle: Options.FiPauseCircle,
  PenTool: Options.FiPenTool,
  Percent: Options.FiPercent,
  Phone: Options.FiPhone,
  PhoneCall: Options.FiPhoneCall,
  PhoneForwarded: Options.FiPhoneForwarded,
  PhoneIncoming: Options.FiPhoneIncoming,
  PhoneMissed: Options.FiPhoneMissed,
  PhoneOff: Options.FiPhoneOff,
  PhoneOutgoing: Options.FiPhoneOutgoing,
  PieChart: Options.FiPieChart,
  Play: Options.FiPlay,
  PlayCircle: Options.FiPlayCircle,
  Plus: Options.FiPlus,
  PlusCircle: Options.FiPlusCircle,
  PlusSquare: Options.FiPlusSquare,
  Pocket: Options.FiPocket,
  Power: Options.FiPower,
  Printer: Options.FiPrinter,
  Radio: Options.FiRadio,
  RefreshCcw: Options.FiRefreshCcw,
  RefreshCw: Options.FiRefreshCw,
  Repeat: Options.FiRepeat,
  Rewind: Options.FiRewind,
  RotateCcw: Options.FiRotateCcw,
  RotateCw: Options.FiRotateCw,
  Rss: Options.FiRss,
  Save: Options.FiSave,
  Scissors: Options.FiScissors,
  Search: Options.FiSearch,
  Send: Options.FiSend,
  Server: Options.FiServer,
  Settings: Options.FiSettings,
  Share: Options.FiShare,
  Share2: Options.FiShare2,
  Shield: Options.FiShield,
  ShieldOff: Options.FiShieldOff,
  ShoppingBag: Options.FiShoppingBag,
  ShoppingCart: Options.FiShoppingCart,
  Shuffle: Options.FiShuffle,
  Sidebar: Options.FiSidebar,
  SkipBack: Options.FiSkipBack,
  SkipForward: Options.FiSkipForward,
  Slack: Options.FiSlack,
  Slash: Options.FiSlash,
  Sliders: Options.FiSliders,
  Smartphone: Options.FiSmartphone,
  Smile: Options.FiSmile,
  Speaker: Options.FiSpeaker,
  Square: Options.FiSquare,
  Star: Options.FiStar,
  StopCircle: Options.FiStopCircle,
  Sun: Options.FiSun,
  Sunrise: Options.FiSunrise,
  Sunset: Options.FiSunset,
  Table: Options.FiTable,
  Tablet: Options.FiTablet,
  Tag: Options.FiTag,
  Target: Options.FiTarget,
  Terminal: Options.FiTerminal,
  Thermometer: Options.FiThermometer,
  ThumbsDown: Options.FiThumbsDown,
  ThumbsUp: Options.FiThumbsUp,
  ToggleLeft: Options.FiToggleLeft,
  ToggleRight: Options.FiToggleRight,
  Tool: Options.FiTool,
  Trash: Options.FiTrash,
  Trash2: Options.FiTrash2,
  Trello: Options.FiTrello,
  TrendingDown: Options.FiTrendingDown,
  TrendingUp: Options.FiTrendingUp,
  Triangle: Options.FiTriangle,
  Truck: Options.FiTruck,
  Tv: Options.FiTv,
  Twitch: Options.FiTwitch,
  Twitter: Options.FiTwitter,
  Type: Options.FiType,
  Umbrella: Options.FiUmbrella,
  Underline: Options.FiUnderline,
  Unlock: Options.FiUnlock,
  Upload: Options.FiUpload,
  UploadCloud: Options.FiUploadCloud,
  User: Options.FiUser,
  UserCheck: Options.FiUserCheck,
  UserMinus: Options.FiUserMinus,
  UserPlus: Options.FiUserPlus,
  UserX: Options.FiUserX,
  Users: Options.FiUsers,
  Video: Options.FiVideo,
  VideoOff: Options.FiVideoOff,
  Voicemail: Options.FiVoicemail,
  Volume: Options.FiVolume,
  Volume1: Options.FiVolume1,
  Volume2: Options.FiVolume2,
  VolumeX: Options.FiVolumeX,
  Watch: Options.FiWatch,
  Wifi: Options.FiWifi,
  WifiOff: Options.FiWifiOff,
  Wind: Options.FiWind,
  X: Options.FiX,
  XCircle: Options.FiXCircle,
  XOctagon: Options.FiXOctagon,
  XSquare: Options.FiXSquare,
  Youtube: Options.FiYoutube,
  Zap: Options.FiZap,
  ZapOff: Options.FiZapOff,
  ZoomIn: Options.FiZoomIn,
  ZoomOut: Options.FiZoomOut,
};

const CoreIcon = {
  Product: Icon.Box,
  Team: Icon.Users,
  League: Icon.Users,
  Donation: Icon.DollarSign,
  Coach: Icon.UserCheck,
  User: Icon.User,
  Admin: Icon.Shield,
  Shipping: Icon.Truck,
  Order: Icon.List,
  Bag: Icon.ShoppingBag,
  Empty: Icon.Square,
};

export default Icon;

export { CoreIcon };
