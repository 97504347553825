import Table from '@components/display/Table';
import BasePage from '@components/layout/BasePage';
import schema from '@schema/index';
import { LeagueTypes } from '@schema/league/types';
import React, { FC, useState } from 'react';
import LeagueForm from './components/LeagueForm';
import Action from '@components/input/Action';
import Icon from '@components/display/Icon';

const Leagues: FC = () => {
  const [variables, setVariables] = useState<LeagueTypes.List.Variables>({
    skip: 0,
    take: 1000,
    filter: {},
  });

  const { data, loading } = schema.league.list({ variables });

  const leagues = data?.leagueList.leagues ?? [];

  return (
    <LeagueForm>
      {({ onClick }) => (
        <BasePage title="Leagues" loading={loading}>
          <Table
            stickyHeader
            size="lg"
            rows={leagues}
            onClick={onClick}
            columns={[
              {
                header: 'Name',
                content: (row) => row.name,
              },
              {
                header: 'Teams',
                content: (row) => row.teams.length,
              },
            ]}
          />
          <Action
            size="lg"
            endDecorator={<Icon.Plus />}
            onClick={() => onClick()}
          >
            Create League
          </Action>
        </BasePage>
      )}
    </LeagueForm>
  );
};

export default Leagues;
