import { List } from '@schema/list/list';
import { League } from './league';
import { gql } from '@apollo/client';

export class LeagueList extends List {
  leagues!: League[];

  static fragment = gql`
    fragment LeagueListFragment on LeagueList {
      total
      skip
      take
      leagues {
        ...LeagueFragment
      }
    }
  `;
}
