import Icon from '@components/display/Icon';
import { Box, Button, Typography } from '@mui/joy';
import { Team } from '@schema/team/types/team';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface NotReadyProps {
  team: Team;
}

const NotReady: FC<NotReadyProps> = ({ team }) => {
  const nav = useNavigate();
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 2,
      }}
    >
      <Typography
        color="primary"
        sx={{
          fontWeight: 'bold',
        }}
      >
        <em>Coming Soon</em>
      </Typography>
      <Typography level="h3">{team.name} Team Store</Typography>
      <Typography level="body-lg">
        Once the coach has completed the onboarding process, the team store will
        be available.
      </Typography>
      <Button
        onClick={() => {
          nav('/');
        }}
        startDecorator={<Icon.ArrowLeft />}
      >
        Home
      </Button>
      <Box p={8} />
    </Box>
  );
};

export default NotReady;
