import Icon from '@components/display/Icon';
import BasePage from '@components/layout/BasePage';
import Nav from '@components/layout/Nav';
import { Box, Button, IconButton, Input, Stack, Typography } from '@mui/joy';
import schema from '@schema/index';
import { TeamTypes } from '@schema/team/types';
import { User } from '@schema/user/types/user';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TeamList: FC = () => {
  const nav = useNavigate();

  const [variables, setVariables] = useState<TeamTypes.List.Variables>({
    skip: 0,
    take: 1000,
    filter: {
      onboarded: true,
    },
  });

  const { data, loading } = schema.team.list({ variables });

  const teams = data?.teamList.teams ?? [];

  return (
    <Nav>
      <BasePage title="Find Your Team" loading={loading} maxWidth="xs">
        <Input
          autoFocus
          sx={{ p: 2 }}
          variant="soft"
          startDecorator={<Icon.Search />}
          placeholder="Search by Name, Coach, or League..."
          value={variables.filter?.phrase ?? ''}
          onChange={(e) =>
            setVariables({
              ...variables,
              filter: { ...variables.filter, phrase: e.target.value },
            })
          }
          endDecorator={
            variables.filter?.phrase && (
              <IconButton
                onClick={() =>
                  setVariables({
                    ...variables,
                    filter: { ...variables.filter, phrase: '' },
                  })
                }
              >
                <Icon.X />
              </IconButton>
            )
          }
        />
        <Box p={1} />
        <Stack gap={2}>
          <AnimatePresence>
            {teams.map((team, i) => (
              <motion.div
                key={`team-${team._id}`}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -5 }}
                transition={{ delay: i * 0.05 }}
              >
                <Button
                  onClick={() => {
                    nav(team.tag);
                  }}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                  fullWidth
                  variant="soft"
                  size="lg"
                  color="neutral"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography level="title-lg">{team.name}</Typography>
                    {team.coach && (
                      <Typography sx={{ fontWeight: 500 }} level="body-sm">
                        Coached by {User.getName(team.coach)}
                      </Typography>
                    )}
                  </Box>
                  <Icon.ArrowRight />
                </Button>
              </motion.div>
            ))}
          </AnimatePresence>
        </Stack>
      </BasePage>
    </Nav>
  );
};

export default TeamList;
