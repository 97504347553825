import { gql } from '@apollo/client';
import { Identified } from '@schema/identified/identified';

export class League extends Identified {
  name!: string;
  teams!: { _id: string; name: string }[];

  static fragment = gql`
    fragment LeagueFragment on League {
      _id
      name
      teams {
        _id
        name
      }
    }
  `;
}
