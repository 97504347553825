import Icon, { CoreIcon } from '@components/display/Icon';
import { Button } from '@mui/joy';
import { useClaims } from '@providers/ClaimsProvider';
import { Role } from 'auth/role';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AdminButton: FC = () => {
  const { pathname } = useLocation();
  const nav = useNavigate();

  const { claims } = useClaims();

  const active = pathname.startsWith('/admin');

  if (!claims || claims.role === Role.User) {
    return null;
  }

  return (
    <Button
      onClick={() => nav('/admin')}
      color={active ? 'primary' : 'neutral'}
      variant="plain"
      startDecorator={<CoreIcon.Admin />}
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
      }}
    >
      Admin
    </Button>
  );
};

export default AdminButton;
