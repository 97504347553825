import Form from '@components/layout/Form';
import { Button, FormControl, FormLabel, Input } from '@mui/joy';
import { useAlert } from '@providers/AlertProvider';
import schema from '@schema/index';
import { LeagueTypes } from '@schema/league/types';
import { League } from '@schema/league/types/league';
import { LeagueCreate } from '@schema/league/types/league.create';
import { LeagueUpdate } from '@schema/league/types/league.update';
import React, { FC, ReactNode, useState } from 'react';

export interface LeagueFormProps {
  children: (provided: { onClick: (league?: League) => void }) => ReactNode;
}

type State =
  | ({ type: 'create' } & LeagueCreate)
  | ({ type: 'update' } & LeagueUpdate);

const LeagueForm: FC<LeagueFormProps> = ({ children }) => {
  const [id, setId] = useState<string | null>(null);
  const [state, setState] = useState<State | null>(null);
  const alert = useAlert();

  const onClose = () => {
    setState(null);
    setId(null);
  };

  const [handleCreate, { loading: createLoading }] = schema.league.create({
    variables:
      state && state.type === 'create'
        ? {
            data: { name: state.name },
          }
        : undefined,
    refetchQueries: [LeagueTypes.List.query, LeagueTypes.Count.query],
    onCompleted: () => {
      onClose();
    },
  });

  const [handleUpdate, { loading: updateLoading }] = schema.league.update({
    variables:
      state && state.type === 'update' && id
        ? {
            id,
            data: { name: state.name },
          }
        : undefined,
    refetchQueries: [
      LeagueTypes.List.query,
      LeagueTypes.Count.query,
      LeagueTypes.Read.query,
    ],
    onCompleted: () => {
      onClose();
    },
  });

  const loading = createLoading || updateLoading;

  const onSubmit = () => {
    if (!state) return;
    else if (!state.name) alert("Please enter the league's name.", 'info');
    else if (state.type === 'create') handleCreate();
    else if (state.type === 'update') handleUpdate();
  };

  return (
    <>
      {children({
        onClick: (clicked) => {
          if (clicked) {
            setState({ type: 'update', name: clicked.name });
            setId(clicked._id);
          } else {
            setState({ type: 'create', name: '' });
          }
        },
      })}
      <Form
        open={Boolean(state)}
        onClose={onClose}
        title={state?.type === 'create' ? 'Create League' : 'Update League'}
        action={
          <Button type="submit" fullWidth size="lg" loading={loading}>
            Save
          </Button>
        }
        onSubmit={onSubmit}
      >
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Name"
            variant="soft"
            size="lg"
            value={state?.name ?? ''}
            onChange={(e) => {
              if (!state) return;
              setState({ ...state, name: e.target.value });
            }}
          />
        </FormControl>
      </Form>
    </>
  );
};

export default LeagueForm;
