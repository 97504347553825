import Table from '@components/display/Table';
import BasePage from '@components/layout/BasePage';
import schema from '@schema/index';
import { TeamTypes } from '@schema/team/types';
import React, { FC, useState } from 'react';
import Action from '@components/input/Action';
import Icon, { CoreIcon } from '@components/display/Icon';
import { Box, IconButton, Typography, useTheme } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import TeamForm from '../TeamForm';

const TeamList: FC = () => {
  const { palette } = useTheme();
  const nav = useNavigate();

  const [variables, setVariables] = useState<TeamTypes.ListDetail.Variables>({
    skip: 0,
    take: 1000,
    filter: {},
  });

  const { data, loading } = schema.team.listDetail({ variables });

  const teams = data?.teamList.teams ?? [];

  return (
    <BasePage title="Teams" loading={loading}>
      <Table
        stickyHeader
        size="lg"
        rows={teams}
        onClick={(clicked) => {
          nav(clicked._id);
        }}
        columns={[
          {
            header: 'Name',
            content: (row) => row.name,
          },
          {
            header: 'Onboarding',
            content: (row) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    height: '12px',
                    width: '12px',
                    borderRadius: '50%',
                    background: row.onboarded
                      ? palette.success[500]
                      : palette.danger[500],
                  }}
                />
                <Typography>
                  {row.onboarded ? 'Complete' : 'Incomplete'}
                </Typography>
              </Box>
            ),
          },
          {
            header: 'League',
            content: (row) => row.league?.name ?? 'Unassigned',
          },
          {
            header: 'Coach',
            content: (row) =>
              row.coach
                ? row.coach.displayName ??
                  row.coach.email ??
                  row.coach.phoneNumber ??
                  'Unnamed Coach'
                : 'Unassigned',
            min: 'md',
          },
          {
            header: (
              <Box>
                <Typography>Balance</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography sx={{ opacity: 0.5 }} level="body-xs">
                    Pending
                  </Typography>
                  <Icon.ArrowRight
                    style={{
                      opacity: 0.5,
                      fontSize: '.75rem',
                    }}
                  />
                  <Typography level="body-xs">Available</Typography>
                </Box>
              </Box>
            ),
            content: (row) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    opacity: 0.5,
                  }}
                >
                  ${parseInt((row.balance.pending / 100).toFixed(0))}
                </Typography>
                <Icon.ArrowRight
                  style={{
                    opacity: 0.5,
                  }}
                />
                <Typography>
                  ${parseInt((row.balance.available / 100).toFixed(0))}
                </Typography>
              </Box>
            ),
          },
          {
            header: '',
            content: (row) => (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const url = `${window.location.origin}/teams/${row.tag}`;
                  window.open(url, '_blank');
                }}
              >
                <CoreIcon.Bag />
              </IconButton>
            ),
            width: '72px',
          },
        ]}
      />
      <TeamForm>
        {({ onClick }) => (
          <Action
            size="lg"
            endDecorator={<Icon.Plus />}
            onClick={() => onClick()}
          >
            Create Team
          </Action>
        )}
      </TeamForm>
    </BasePage>
  );
};

export default TeamList;
