import datahook from '@hooks/datahook';
import { LeagueTypes as T } from './types';

const leagueService = {
  read: datahook.query<T.Read.Result, T.Read.Variables>(T.Read.query),
  list: datahook.query<T.List.Result, T.List.Variables>(T.List.query),
  all: () => {
    const { data, error, loading } = datahook.query<
      T.List.Result,
      T.List.Variables
    >(T.List.query)({
      variables: { filter: {}, skip: 0, take: 1000 },
    });

    const leagues = data?.leagueList.leagues ?? [];

    return { leagues, error, loading };
  },
  count: datahook.query<T.Count.Result, T.Count.Variables>(T.Count.query),
  create: datahook.mutation<T.Create.Result, T.Create.Variables>(
    T.Create.mutation,
  ),
  update: datahook.mutation<T.Update.Result, T.Update.Variables>(
    T.Update.mutation,
  ),
};

export default leagueService;
