import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import OrdersList from './components/OrdersList';
import OrderDetail from './components/OrderDetail';

const Orders: FC = () => {
  return (
    <Routes>
      <Route index element={<OrdersList />} />
      <Route path=":id" element={<OrderDetail />} />
    </Routes>
  );
};

export default Orders;
