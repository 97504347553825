import React, { FC, ReactNode } from 'react';
import { CoreIcon } from '../Icon';
import { Box } from '@mui/joy';

export interface EmptyProps {
  icon?: ReactNode;
  message?: ReactNode;
  p?: number;
}

const Empty: FC<EmptyProps> = ({
  icon = <CoreIcon.Empty />,
  message = 'No Data',
  p = 4,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        p,
      }}
    >
      {icon}
      {message}
    </Box>
  );
};

export default Empty;
